import { ConnectedInput } from '../shared/Input';
import { getClass } from '../../utils';
import AddressAutocomplete from '../shared/AddressAutocomplete/AddressAutocompleteComponent';
import EmailInput from '../shared/EmailInput';
import Form from '../shared/Form';
import PasswordInput from '../shared/PasswordInput';
import React, { FC } from 'react';
import styles from './styles.module.scss';
import { FieldValues, SubmitHandler } from 'react-hook-form';

type Props = {
    handleSubmit: SubmitHandler<FieldValues>;
};

const SignUpForm: FC<Props> = ({ handleSubmit }) => (
    <Form submitText='Create account' onSubmit={handleSubmit} className={getClass(styles, 'signUpForm')}>
        <ConnectedInput name='first_name' label='First name' required />
        <ConnectedInput name='last_name' label='Last name' required />
        <EmailInput name='email' label='Email' required />
        <PasswordInput name='password' label='Password' />
        <AddressAutocomplete name='address' label='Street address' isConnected description="We'll use your address to find your reps" />
    </Form>
);

export default SignUpForm;
