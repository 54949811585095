import { closeOutline } from 'ionicons/icons';
import { getClass } from '../../utils';
import { InputStyleTypes } from '../shared/Input/types';
import { IonIcon } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { useAuthenticatedUser } from '../AuthenticatedUser';
import AddressAutocomplete from '../shared/AddressAutocomplete';
import Button, { ColorTypes } from '../shared/Button';
import PageLayout from '../PageLayout';
import peopleBackground from '../../assets/people-background.jpg';
import React, { useState } from 'react';
import styles from './home.module.scss';
import UprootLogoStackedSVG from '../../assets/UprootLogoStackedSVG';
import withTracker from '../withTracker';

const HomeComponent = ({ history }: RouteComponentProps) => {
    const [, setUserContext] = useAuthenticatedUser();
    const [inputFieldAddress, setAddress] = useState<string>();
    const toggleIcon = <IonIcon icon={closeOutline} className={styles.toggleIcon} />;

    const submitPlaceSelected = () => {
        history.push(`/my-reps?address=${inputFieldAddress}`);
    };

    const handlePlaceSelected = (address: string): void => {
        setUserContext((prevState) => ({ ...prevState, curiosityAddress: address }));
        setAddress(address);
    };

    return (
        <PageLayout>
            <div className={getClass(styles, 'container')}>
                <div className={getClass(styles, 'main')}>
                    <div className={`${getClass(styles, 'headingSection')} ion-padding-vertical`}>
                        <h2 className={`${getClass(styles, 'headingHeader')} uproot-h2`}>Plant seeds of change in your community</h2>
                        <p className={getClass(styles, 'headingBody')}>
                            Vote for officials who support what you believe in and weed out the ones who don&apos;t.
                        </p>
                    </div>
                    <div className={getClass(styles, 'imagerySection')}>
                        <img className={getClass(styles, 'imageryBackground')} src={peopleBackground} alt='people' />
                        <UprootLogoStackedSVG className={getClass(styles, 'imageryBody')} />
                    </div>
                    <div className={getClass(styles, 'callToActionSection')}>
                        <h2 className={`${getClass(styles, 'callToActionHeader')} uproot-h2`}>Find your reps and learn their voting records</h2>
                        <section className={getClass(styles, 'callToActionForm')}>
                            <div className={getClass(styles, 'addressAutocompleteWrapper')}>
                                <AddressAutocomplete
                                    placeholder="What's your address?"
                                    styleType={InputStyleTypes.Borderless}
                                    onPlaceSelected={handlePlaceSelected}
                                    toggleIcon={toggleIcon}
                                />
                            </div>
                            <Button
                                colorType={ColorTypes.Secondary}
                                className={getClass(styles, 'getStartedCTA')}
                                onClick={submitPlaceSelected}
                                disabled={!inputFieldAddress}
                            >
                                Get started
                            </Button>
                        </section>
                    </div>
                </div>
            </div>
        </PageLayout>
    );
};

export default withTracker(HomeComponent);
