import { useHistory, useLocation } from 'react-router-dom';
import MobileNavigationComponent from './MobileNavigationComponent';
import React, { useEffect, useState } from 'react';

type Props = {
    onSignOut: () => void;
};

const home = '/';

function getPathToNavigateBackTo(currentPathName: string) {
    const [, path, params] = currentPathName.split('/');

    switch (path) {
        case 'my-reps':
            if (params) {
                return `/${path}`;
            } else {
                return home;
            }
        default:
            return home;
    }
}
const MobileNavigationContainer: React.FC<Props> = ({ onSignOut }) => {
    const [showBackButton, setShowBackButton] = useState(false);
    const location = useLocation();
    const history = useHistory();
    useEffect(() => {
        setShowBackButton(location.pathname !== home);
    }, [location]);

    // Ionic back button has double click issue https://github.com/ionic-team/ionic-framework/issues/18455
    const onNavigateBack = () => {
        const pathToNavigateBackTo = getPathToNavigateBackTo(location.pathname);
        history.push(pathToNavigateBackTo);
    };

    return <MobileNavigationComponent onNavigateBack={onNavigateBack} showBackButton={showBackButton} onSignOut={onSignOut} />;
};

export default MobileNavigationContainer;
