import { IonRouterLink } from '@ionic/react';
import { NavLink } from 'react-router-dom';
import { useAuthenticatedUser } from '../../AuthenticatedUser';
import { useSignInModal, useSignUpModal } from '../../ModalsProvider';
import Button, { ColorTypes } from '../../shared/Button';
import React from 'react';
import styles from './desktopnavigation.module.scss';
import UprootLogoExtendedSVG from '../../../assets/UprootLogoExtendedSVG';
import routes from '../../../routes';

type Props = {
    onSignOut: () => void;
};

const DesktopNavigation: React.FC<Props> = ({ onSignOut }) => {
    const setSignInModalState = useSignInModal();
    const setSignUpModalState = useSignUpModal();
    const [{ isAuthenticated }] = useAuthenticatedUser();

    return (
        <nav className={styles.desktopNav}>
            <IonRouterLink routerLink='/'>
                <UprootLogoExtendedSVG />
            </IonRouterLink>
            <div className={styles.linksContainer}>
                <ul className={styles.menu}>
                    {routes.map((route, index) => {
                        return (
                            !!route.name &&
                            (!route.auth || (route.auth && isAuthenticated)) && (
                                <li key={index}>
                                    <NavLink exact activeClassName={styles.active} className={styles.item} to={route.path}>
                                        {route.name}
                                    </NavLink>
                                </li>
                            )
                        );
                    })}
                    {isAuthenticated ? (
                        <React.Fragment>
                            <li>
                                <span className={styles.item} onClick={onSignOut}>
                                    Sign out
                                </span>
                            </li>
                        </React.Fragment>
                    ) : (
                        <>
                            <li>
                                <span className={styles.item} onClick={() => setSignInModalState(true)}>
                                    Sign in
                                </span>
                            </li>
                            <Button colorType={ColorTypes.Tertiary} size='small' className={styles.signUp} onClick={() => setSignUpModalState(true)}>
                                Sign up
                            </Button>
                        </>
                    )}
                </ul>
            </div>
        </nav>
    );
};

export default DesktopNavigation;
