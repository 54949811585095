import { useSubmitSubscriberMutation } from '../../../@types/generated-gql-typed-hooks';
import React from 'react';
import SignUpNewsletter from './SignUpNewsletter';
import { useToast } from '../../WithToaster/WithToasterComponent';
import { useToasterWithError } from '../../WithToaster';

const SignUpNewsletterContainer: React.FC = () => {
    const setToasterWithError = useToasterWithError();
    const { presentSuccessToast } = useToast();

    const [subscribeUser] = useSubmitSubscriberMutation({
        onError: (err) => setToasterWithError(err),
        onCompleted: () => {
            presentSuccessToast('You have successfully signed up for our newsletter!');
        },
    });

    const handleSubmit = async ({ email }: any): Promise<void> => {
        const variables = {
            email,
        };

        await subscribeUser({ variables });
    };

    return <SignUpNewsletter onSubmit={handleSubmit} />;
};

export default SignUpNewsletterContainer;
