import { UploadFile } from '../@types/generated-gql-typed-hooks';
import _get from 'lodash/get';
import DEFAULT_PHOTO_URI from '../assets/default-user-avatar.png';

type Photo = {
    photoUrl?: string | null;
    picture?: Pick<UploadFile, 'url' | 'formats'> | null;
};

const selectRepPhoto = ({ photoUrl, picture }: Photo): string => {
    const thumbnailUrl = _get(picture, 'formats.thumbnail.url', null);
    const picUrl = picture?.url;
    const baseUrl = process.env.REACT_APP_BACKEND_IMAGE_URL || '';
    if (thumbnailUrl) {
        return `${baseUrl}${thumbnailUrl}`;
    } else if (picUrl) {
        return `${baseUrl}${picUrl}`;
    } else if (photoUrl) {
        const srcURL = new URL(photoUrl);
        const isHttps = srcURL.protocol.includes('https');
        const url = srcURL.href;

        return !isHttps ? url.replace('http', 'https') : url;
    }

    return DEFAULT_PHOTO_URI;
};

export default selectRepPhoto;
