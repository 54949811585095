import { getClass } from '../../utils';
import cn from 'classnames';
import React from 'react';
import styles from './header.module.scss';

type Props = {
    pageTitle: string;
    subline?: React.ReactNode;
    changeAddress?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    children?: JSX.Element;
    className?: string;
    isMobileSticky?: boolean;
};

const Header: React.FC<Props> = ({ pageTitle, subline, children, className, isMobileSticky }) => {
    const classNames = cn(className, getClass(styles, 'headerTitle'));

    return (
        <div className={cn(getClass(styles, 'headerContainer'), isMobileSticky && getClass(styles, 'isMobileSticky'))}>
            <h2 className={`${classNames} uproot-h2 ion-padding-horizontal`}>{pageTitle}</h2>
            <span className={`${getClass(styles, 'subline')} ion-padding-horizontal ion-padding-vertical`}>{subline}</span>
            {children}
        </div>
    );
};

export default Header;
