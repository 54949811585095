import ConnectedInput from '../Input/ConnectedInput';
import Form from '../Form';
import React from 'react';
import styles from './styles.module.scss';
import { FieldValues, SubmitHandler } from 'react-hook-form';

type ImageSuggestionFormProps = {
    onSubmit: SubmitHandler<FieldValues>;
};

const ImageSuggestionForm: React.FC<ImageSuggestionFormProps> = ({ onSubmit }) => {
    return (
        <Form submitText='Submit Image' onSubmit={onSubmit} className={styles.imageSuggestionForm}>
            <ConnectedInput name='image_source' label='Image source' defaultValue={''} required />
            <ConnectedInput name='image_link' label='Image Link' defaultValue={''} required />
        </Form>
    );
};

export default ImageSuggestionForm;
