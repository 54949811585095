import { AuthContextType, AuthenticatedUser, DispatchAuthenticatedUser } from './typeDefs';
import { AuthenticatedUserContext, DispatchAuthenticatedUserContext } from './authContext';
import React from 'react';

const useAuthenticatedUserContext = (): AuthContextType => {
    const context = React.useContext(AuthenticatedUserContext);

    if (context === null || context === undefined) {
        throw new Error('useAuthenticatedUserContext must beused within a AuthenticatedUserProvider');
    }

    return context;
};

const useDispatchAuthenticatedUserContext = (): DispatchAuthenticatedUser => {
    const context = React.useContext(DispatchAuthenticatedUserContext);

    if (context === undefined) {
        throw new Error('useDispatchAuthenticatedUserContext must be used within a AuthenticatedUserProvider');
    }

    return context;
};

export const useAuthenticatedUser = (): [AuthenticatedUser, DispatchAuthenticatedUser, () => void] => {
    // TODO: For 'useDispatchAuthenticatedUserContext' Define a function that merges the value of context coming in with existing user context

    const { authenticatedUser, signOut } = useAuthenticatedUserContext() as AuthContextType;
    return [authenticatedUser, useDispatchAuthenticatedUserContext(), signOut] as [AuthenticatedUser, DispatchAuthenticatedUser, () => void];
};
