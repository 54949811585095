import EmailInput from '../shared/EmailInput';
import Form from '../shared/Form';
import Link, { ColorTypes } from '../shared/Link';
import PasswordInput from '../shared/PasswordInput';
import React from 'react';
import styles from './styles.module.scss';
import { FieldValues, SubmitHandler } from 'react-hook-form';

type Props = {
    handleSubmit: SubmitHandler<FieldValues>;
    onForgotPassword: () => void;
};

const SignInForm: React.FC<Props> = ({ handleSubmit, onForgotPassword }) => {
    return (
        <>
            <Form onSubmit={handleSubmit} submitText='Sign in'>
                <EmailInput name='email' label='Email' required />
                <PasswordInput name='password' label='Password' />
            </Form>
            <Link className={`${styles.forgotPassword} clickLink`} onClick={onForgotPassword} colorType={ColorTypes.Primary}>
                Forgot password?
            </Link>
        </>
    );
};

export default SignInForm;
