import Button, { ColorTypes } from '../shared/Button';
import React, { FC } from 'react';
import styles from './styles.module.scss';

type Props = {
    handleCancel: () => void;
    handleSubmit: (input: string) => void;
};

const DeleteProfileForm: FC<Props> = ({ handleCancel, handleSubmit }) => (
    <div className={styles.deleteProfileForm}>
        <Button className={styles.yesButton} onClick={handleSubmit} colorType={ColorTypes.Primary}>
            Yes
        </Button>
        <Button colorType={ColorTypes.Tertiary} onClick={handleCancel}>
            Cancel
        </Button>
    </div>
);

export default DeleteProfileForm;
