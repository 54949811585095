import { get } from 'lodash';
import { ComponentContactsContacts, useOfficialQuery } from '../../@types/generated-gql-typed-hooks';
import { RouteComponentProps } from 'react-router';
import { selectRepPhoto } from '../../utils';
import React, { useEffect, useState } from 'react';
import RepProfilePageComponent from './RepProfilePageComponent';
import withTracker from '../withTracker';

type Contacts = Array<Pick<ComponentContactsContacts, 'type' | 'value'> | undefined | null> | undefined | null;

const RepProfilePageContainer: React.FC<RouteComponentProps<any>> = ({ match }) => {
    const [repDetails, setRepDetails] = useState({
        imgSrc: '',
        name: '',
        title: '',
        party: '',
        address: '',
    });
    const { data } = useOfficialQuery({ variables: { id: match.params.id } });

    useEffect(() => {
        if (data && data.official) {
            setRepDetails({
                imgSrc: selectRepPhoto({ picture: data.official?.data?.attributes?.picture?.data?.attributes }),
                name: data.official?.data?.attributes?.name || '',
                title: data.official?.data?.attributes?.office || '',
                party: data.official?.data?.attributes?.party || '',
                address: getOfficialAddress(data.official?.data?.attributes?.contacts),
            });
        }
    }, [data]);

    const getOfficialAddress = (contacts: Contacts): string => {
        if (!contacts || !contacts.length) return '';
        // return first address found
        const addressObj = contacts.find((field) => field && field.type === 'address');
        // ensure address field has a value propery
        const address = get(addressObj, 'value') || '';

        return address;
    };

    return <RepProfilePageComponent repDetails={repDetails} contentTitle='Representative Bills' />;
};

export default withTracker(RepProfilePageContainer);
