import { FormProvider, useForm, UseFormReturn, SubmitHandler, FieldValues } from 'react-hook-form';
import { isEmpty } from 'lodash';
import Button, { ColorTypes } from '../Button';
import cn from 'classnames';
import InputErrorMessage from '../InputErrorMessage';
import React, { ReactElement } from 'react';
import styles from './styles.module.scss';

type Props = {
    children?: ReactElement | ReactElement[];
    onSubmit: SubmitHandler<FieldValues>;
    className?: string;
    submitText: string;
    alwaysEnableSubmit?: boolean;
    render?: (val: UseFormReturn<FieldValues>) => JSX.Element;
    resetOnSuccess?: boolean;
};

const Form: React.FC<Props> = ({ className, children, onSubmit, submitText, render, alwaysEnableSubmit = false, resetOnSuccess = false }) => {
    const methods = useForm<FieldValues>();

    const handleSubmit: SubmitHandler<FieldValues> = async (data) => {
        await onSubmit(data);
        if (resetOnSuccess) {
            methods.reset();
        }
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)} className={cn(styles.formContainer, className)}>
                {!isEmpty(methods.formState.errors) && <InputErrorMessage />}
                {children}
                {render && render(methods)}
                <Button type='submit' colorType={ColorTypes.Primary} disabled={!alwaysEnableSubmit && !methods.formState.isDirty}>
                    {submitText}
                </Button>
            </form>
        </FormProvider>
    );
};

export default Form;
