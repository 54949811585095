import { createBrowserHistory } from 'history';
import queryString from 'query-string';

type ParamsType = {
    searchQuery: string;
    searchParams: { [key: string]: string | string[] | null | undefined };
};

// @ts-ignore: location is exported
const getSearchParams = (location: window.location = createBrowserHistory().location): ParamsType => {
    const searchParams = { ...queryString.parse(location ? location.search : '') };
    const searchQuery = String(searchParams.query || '');
    delete searchParams.query;

    return { searchQuery, searchParams };
};

export default getSearchParams;
