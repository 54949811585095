import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { IonLoading } from '@ionic/react';
import PageLayoutComponent from '../PageLayout';
import React from 'react';
import styles from './styles.module.scss';

type SplitPageLayoutComponentProps = {
    leftPane: React.ReactNode;
    rightPane: React.ReactNode;
    contentTitle: string;
    isLoading?: boolean;
};

const SplitPageLayoutComponent: React.FC<SplitPageLayoutComponentProps> = ({ leftPane, rightPane, contentTitle, isLoading = false }) => {
    return (
        <PageLayoutComponent>
            <IonLoading isOpen={isLoading} message='Please wait...' />
            <IonGrid>
                <IonRow>
                    <IonCol sizeXs='12' sizeSm='12' sizeMd='12' sizeLg='2' className={styles.leftPaneContainer}>
                        {leftPane}
                    </IonCol>
                    <IonCol>
                        <div className={styles.rightPaneContainer}>
                            <div className={styles.contentTitle}>{contentTitle}</div>
                            {rightPane}
                        </div>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </PageLayoutComponent>
    );
};

export default SplitPageLayoutComponent;
