import { getClass } from '../../utils';
import Header from '../Header';
import PageLayout from '../PageLayout';
import { NavLink } from 'react-router-dom';
import React from 'react';
import styles from './styles.module.scss';
import withTracker from '../withTracker';

const AboutUsComponent: React.FC<any> = () => {
    return (
        <PageLayout withBottomSpacing>
            <Header pageTitle='About us' />
            <div className={getClass(styles, 'container')}>
                <div className={getClass(styles, 'content')}>
                    <h2 className={`uproot-h2 ${styles.heading}`}>Uproot.</h2>
                    <h3 className={`uproot-h3 ${styles.subheading}`}>Inspired by the Need for Change.</h3>
                    <p>
                        Uproot was inspired by the concept of a digital democracy, where technology empowers citizens to stay informed and influence
                        the decisions that shape their lives. In a digital democracy, everyone has equal access to information, making the political
                        process more transparent and inclusive. But Uproot goes further, striving for equity—ensuring that historically marginalized
                        communities have the tools they need to actively participate.
                    </p>
                    <p>
                        Hearing countless stories of individuals feeling powerless and uninformed reinforced this belief. Uproot seeks to bridge that
                        gap by delivering essential political information and using technology to empower those who have been left out of the
                        conversation. Our vision is to create a truly equitable democracy where everyone can participate, have their voices heard, and
                        effect real change.
                    </p>
                    <h2 className={`uproot-h2 ${styles.heading}`}>Our Mission</h2>
                    <p>
                        At Uproot, our mission is simple: to provide easy-to-find, factual, and free information about elected officials and what they
                        are doing to improve the lives of their constituents. In today&rsquo;s fast-paced political landscape, it&rsquo;s all too easy
                        to feel disconnected or uninformed about the actions of those in power. Uproot exists to bridge that gap, bringing
                        transparency and accountability to governance.
                    </p>
                    <h2 className={`uproot-h2 ${styles.heading}`}>Who is Uproot For?</h2>
                    <p>
                        Uproot is for anyone looking to be more civically engaged. Whether you&rsquo;re a first-time voter, a seasoned activist, or
                        simply someone who wants to stay informed about what&rsquo;s happening in your local government, Uproot is your resource for
                        up-to-date and relevant information.
                    </p>
                    <h2 className={`uproot-h2 ${styles.heading}`}>What Makes Uproot Unique?</h2>
                    <p>
                        What sets Uproot apart is not only our crowd-sourcing model, which allows the public to contribute to the platform, but also
                        our commitment to bringing current and relevant information about politicians into one accessible space. No more sifting
                        through multiple sources or struggling to find out where your representatives stand on issues that matter to you. Uproot
                        centralizes this information, making civic engagement easier than ever before.
                    </p>
                    <h2 className={`uproot-h2 ${styles.heading}`}>Looking Ahead</h2>
                    <p>
                        In the short term, Uproot&rsquo;s goal is to ensure that people have the information they need for the current election.
                        We&rsquo;ve made it easy for users to access voter registration resources by forwarding them to trusted platforms like
                        Vote.org. Looking ahead, we have ambitious plans to turn civic engagement into a regular part of people&rsquo;s lives.
                        We&rsquo;ll be adding features that track upcoming elections, funding sources, city halls, and public appearances by elected
                        officials. We&rsquo;ll also provide information on the bills your representatives are voting on and even offer audio and video
                        clips of their statements, ensuring you&rsquo;re always in the know.
                    </p>
                    <p>
                        <ul>
                            <li>
                                Got any more questions? Check out our
                                <NavLink to={'/faqs'}> FAQ page</NavLink>
                            </li>
                            <li>
                                Want to keep up to date with what we&rsquo;re doing? Sign up to get latest updates.
                            </li>
                            <li>
                                Want to become a contributor? Email us at <a href='mailto:hello@uproot.co'>hello@uproot.co</a>
                            </li>
                        </ul>
                    </p>
                </div>
            </div>
        </PageLayout>
    );
};

export default withTracker(AboutUsComponent);
