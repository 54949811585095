import { getClass } from '../../utils';
import { IonFooter, IonIcon, IonRouterLink } from '@ionic/react';
import { logoFacebook, logoInstagram, logoTwitter } from 'ionicons/icons';
import React from 'react';
import styles from './footer.module.scss';
import { SignUpNewsletter } from './SignUpNewsletter';

type FooterProps = React.HTMLAttributes<HTMLDivElement>;

const Footer: React.FC<FooterProps> = () => {
    return (
        <IonFooter className={getClass(styles, 'footer')}>
            <SignUpNewsletter />
            <div className={styles.logoContainer}>
                <IonRouterLink color='none' href='https://www.facebook.com/uprootco' rel='external' target='blank'>
                    <IonIcon className={styles.logo} icon={logoFacebook} size='large' />
                </IonRouterLink>
                <IonRouterLink color='none' href='https://www.instagram.com/uproot.co' rel='external' target='blank'>
                    <IonIcon className={styles.logo} icon={logoInstagram} size='large' />
                </IonRouterLink>
                <IonRouterLink color='none' href='https://twitter.com/uproot_co' rel='external' target='blank'>
                    <IonIcon className={styles.logo} icon={logoTwitter} size='large' />
                </IonRouterLink>
            </div>
            Copyright &copy; {new Date().getFullYear()} Uproot. All Rights Reserved.
        </IonFooter>
    );
};

export default Footer;
