import { useAuthenticatedUser } from '../AuthenticatedUser';
import { useResendEmailConfirmationMutation } from '../../@types/generated-gql-typed-hooks';
import EmailVerification from './EmailVerification';
import React from 'react';
import { useToast } from '../WithToaster/WithToasterComponent';

const EmailVerificationContainer: React.FC = () => {
    const { presentSuccessToast, presentErrorToast } = useToast();
    const [resendEmailConfirmation] = useResendEmailConfirmationMutation({
        onCompleted: () => {
            presentSuccessToast('Confirmation email sent successfully');
        },
        onError: (error) => {
            if (error) presentErrorToast(error.message);
        },
    });
    const [{ email }] = useAuthenticatedUser();

    const handleResendEmailLink = (): void => {
        if (email) resendEmailConfirmation({ variables: { email } });
        // TODO: Maybe manage throwing an error and catching with ErrorBoundaries
        else console.error('Email address field is empty in User Context');
    };

    return <EmailVerification resetEmail={false} handleResendEmailLink={handleResendEmailLink} />;
};

export default EmailVerificationContainer;
