import React from 'react';

type SetValue = (value: any) => void;
type RemoveValue = () => void;

const useLocalStorage = (key: string, initialValue?: string): [string, SetValue, RemoveValue] => {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = React.useState<string>(() => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue

            return item ? JSON.parse(item) : initialValue;
        } catch {
            // @TODO: handle error
            return initialValue;
        }
    });

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue: SetValue = (value) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch {
            // @TODO: A more advanced implementation would handle the error case
        }
    };

    const removeValue: RemoveValue = () => {
        try {
            // Remove from local storage
            window.localStorage.removeItem(key);
        } catch {
            // @TODO: A more advanced implementation would handle the error case
        }
    };

    return [storedValue, setValue, removeValue];
};

export default useLocalStorage;
