import React, { useState } from 'react';
import { IonFab, IonFabButton, IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import styles from './styles.module.scss'; // For custom styles
import Button, { ColorTypes } from '../../shared/Button';
import { ReactComponent as WhiteFistIcon } from '../../../assets/icons/WhiteFist.svg'; // Adjust the path if necessary
import { stateAbbreviationMap } from '../../../utils/stateMapper';

interface VoteFabProps {
    stateName?: string;
}

const VoteFab: React.FC<VoteFabProps> = ({ stateName }) => {
    const [isExpanded, setIsExpanded] = useState(true);

    const toggleFab = () => {
        setIsExpanded(!isExpanded);
    };
    const stateIsSupported = Object.values(stateAbbreviationMap).includes(stateName || '');
    const stateLink = stateName?.toLowerCase().replace(/\s+/g, '-');

    if (!stateName || !stateIsSupported) {
        return null;
    }

    return (
        <IonFab className={styles.static} slot='fixed' vertical='bottom' horizontal='end'>
            {isExpanded ? (
                <div className={styles.fabExpanded}>
                    <Button colorType={ColorTypes.Primary} href={`https://www.vote.org/state/${stateLink}`}>
                        Make Your Voice Count – Register, See What’s On Your Ballot, and More!
                    </Button>
                    <IonFabButton onClick={toggleFab}>
                        <IonIcon icon={closeOutline} />
                    </IonFabButton>
                </div>
            ) : (
                <IonFabButton onClick={toggleFab}>
                    <WhiteFistIcon />
                </IonFabButton>
            )}
        </IonFab>
    );
};

export default VoteFab;
