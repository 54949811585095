import { ConnectedInput } from '../shared/Input';
import { InputStyleTypes } from '../shared/Input/types';
import { useAuthenticatedUser } from '../AuthenticatedUser';
import { useDeleteProfileModal } from '../ModalsProvider';
import AddressAutocomplete from '../shared/AddressAutocomplete';
import EmailInput from '../shared/EmailInput';
import Form from '../shared/Form';
import Header from '../Header';
import PageLayout from '../PageLayout';
import React, { FC } from 'react';
import SetPasswordForm from '../shared/SetPasswordForm';
import styles from './styles.module.scss';
import { FieldValues, SubmitHandler } from 'react-hook-form';

type SectionTitleProps = {
    children: React.ReactNode;
};

const SectionTitle: FC<SectionTitleProps> = ({ children }) => (
    <div className={styles.header}>
        <div className={styles.accountHeader}>
            <h6 className={'uproot-h6'}>{children}</h6>
        </div>
    </div>
);

type Props = {
    onUpdateInfo: SubmitHandler<FieldValues>;
    onUpdatePassword: SubmitHandler<FieldValues>;
};

const AccountForm: FC<Props> = ({ onUpdateInfo, onUpdatePassword }) => {
    const [{ address, first_name, last_name, email, isAuthenticated }] = useAuthenticatedUser();

    const setDeleteModalState = useDeleteProfileModal();

    return (
        <PageLayout withBottomSpacing>
            <Header pageTitle='Account' />
            <SectionTitle>Personal Info</SectionTitle>

            {isAuthenticated && (
                <React.Fragment>
                    <Form submitText='Update info' onSubmit={onUpdateInfo} className={styles.accountForm} alwaysEnableSubmit={true}>
                        <ConnectedInput name='first_name' label='First name' defaultValue={first_name} required />
                        <ConnectedInput name='last_name' label='Last name' defaultValue={last_name} required />
                        <EmailInput name='email' label='Email' defaultValue={email} required disabled={true} />
                        <AddressAutocomplete
                            toggle={true}
                            selectedAddress={address as string}
                            isConnected={true}
                            name='address'
                            label='Street address'
                            styleType={InputStyleTypes.Outline}
                        />
                        <span className={styles['input-footer-copy']}>{"We'll use your address to find your reps"}</span>
                    </Form>

                    <SectionTitle>Password</SectionTitle>
                    <div className={styles.accountForm}>
                        <SetPasswordForm handleSubmit={onUpdatePassword} submitBtnText={'Update Password'} />
                    </div>

                    <SectionTitle>Delete Profile</SectionTitle>
                    <div className={styles.accountForm}>
                        <span className={`clickLink ${styles.item}`} onClick={() => setDeleteModalState(true)}>
                            Click here to delete your profile.
                        </span>
                    </div>
                </React.Fragment>
            )}
        </PageLayout>
    );
};

export default AccountForm;
