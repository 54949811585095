import React from 'react';
import { Switch } from 'react-router-dom';
import routes from './routes';
import NamedRoute from './components/shared/NamedRoute';
import AuthRoute from './components/AuthRoute';

const AppRoutes: React.FC = () => {
    return (
        <Switch>
            {routes.map((route, index) =>
                route.auth ? (
                    <AuthRoute key={index} path={route.path} component={route.component} exact={route.exact} name={route.name} />
                ) : (
                    <NamedRoute key={index} path={route.path} component={route.component} exact={route.exact} name={route.name} />
                )
            )}
        </Switch>
    );
};

export default AppRoutes;
