import { useAuthenticatedUser } from '../AuthenticatedUser';
import { useForgotPasswordModal, useSignInModal } from '../ModalsProvider';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useToasterWithError } from '../WithToaster';
import LOGIN from './index.graphql';
import merge from 'lodash/merge';
import React from 'react';
import SignInForm from './SignInForm';
import useLocalStorage from '../../hooks/useLocalStorage';
import { useToast } from '../WithToaster/WithToasterComponent';

const SignInFormContainer: React.FC = () => {
    const history = useHistory();
    const setSignInModalState = useSignInModal();
    const setForgotPasswordModalState = useForgotPasswordModal();
    const setToasterWithError = useToasterWithError();
    const [, setStoredValue] = useLocalStorage('token', '');
    const [, updateAuthenticatedUser] = useAuthenticatedUser();
    const { presentSuccessToast } = useToast();

    const [login] = useMutation(LOGIN, {
        onCompleted: ({ login }): any => {
            setStoredValue(login.jwt ?? undefined);
            updateAuthenticatedUser((prevState) => ({
                ...merge(prevState, login.user),
                isAuthenticated: true,
            }));
            setSignInModalState(false);
            history.push(`/my-reps${login.user.address ? `?address=${login.user.address}` : ''}`);
            presentSuccessToast('You have been signed in');
        },
        onError: (err) => {
            setToasterWithError(err);
        },
    });

    const onSubmit = ({ email, password }: any): void => {
        login({ variables: { identifier: email, password: password } });
    };

    const triggerForgotPasswordModal = (): void => {
        setForgotPasswordModalState(true);
        setSignInModalState(false);
    };

    return <SignInForm handleSubmit={onSubmit} onForgotPassword={triggerForgotPasswordModal} />;
};

export default SignInFormContainer;
