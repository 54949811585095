import { chevronBackOutline } from 'ionicons/icons';
import { getClass } from '../../../utils';
import {
    IonButton,
    IonButtons,
    IonHeader,
    IonIcon,
    IonItem,
    IonList,
    IonMenu,
    IonMenuButton,
    IonMenuToggle,
    IonRouterLink,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import { NavLink, useLocation } from 'react-router-dom';
import { useAuthenticatedUser } from '../../AuthenticatedUser';
import { useSignInModal, useSignUpModal } from '../../ModalsProvider';
import Button, { ColorTypes } from '../../shared/Button';
import React, { useEffect, useState } from 'react';
import styles from './mobilenavigation.module.scss';
import UprootLogoExtendedSVG from '../../../assets/UprootLogoExtendedSVG';
import routes from '../../../routes';

type Props = {
    onNavigateBack: () => void;
    onSignOut: () => void;
    showBackButton: boolean;
};

const MobileNavigationComponent: React.FC<Props> = ({ onSignOut, showBackButton, onNavigateBack }) => {
    const setSignInModalState = useSignInModal();
    const setSignUpModalState = useSignUpModal();
    const [{ isAuthenticated }] = useAuthenticatedUser();
    const [currentHeader, setCurrentHeader] = useState<string | undefined>('');
    const location = useLocation();

    useEffect(() => {
        const activeChild = routes.find((route) => {
            if (location.pathname === route.path) {
                return true;
            }

            return false;
        });

        if (activeChild && activeChild.name !== currentHeader) {
            setCurrentHeader(activeChild.name);
        }
    }, [location, routes, currentHeader]);

    return (
        <div className={getClass(styles, 'mobileNav')}>
            <IonHeader className='ion-no-border'>
                <IonToolbar color='tertiary' mode='ios'>
                    <IonButtons slot='start'>
                        {showBackButton ? (
                            <IonButton className={styles.button} onClick={onNavigateBack}>
                                <IonIcon icon={chevronBackOutline} />
                            </IonButton>
                        ) : (
                            <IonMenuButton className={styles.hamburgerMenuIcon} menu='mobileNav' />
                        )}
                    </IonButtons>
                    <IonTitle className={styles.title}>{currentHeader}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonMenu side='start' type='overlay' menuId='mobileNav' contentId='content'>
                <IonList className={styles.mobileNavList}>
                    {routes.map((route, index) => {
                        return (
                            !!route.name &&
                            (!route.auth || (route.auth && isAuthenticated)) && (
                                <IonMenuToggle key={index}>
                                    <IonItem lines='none' routerLink={route.path}>
                                        <NavLink exact activeClassName={styles.active} className={styles.item} to={route.path}>
                                            {route.name}
                                        </NavLink>
                                    </IonItem>
                                </IonMenuToggle>
                            )
                        );
                    })}
                    {isAuthenticated ? (
                        <IonMenuToggle>
                            <IonItem lines='none' routerLink='#' onClick={onSignOut}>
                                <span className={styles.item}>Sign out</span>
                            </IonItem>
                        </IonMenuToggle>
                    ) : (
                        <>
                            <IonMenuToggle>
                                <IonItem lines='none' routerLink='#' onClick={() => setSignInModalState(true)}>
                                    <span className={styles.item}>Sign in</span>
                                </IonItem>
                            </IonMenuToggle>
                            <IonMenuToggle>
                                <Button
                                    colorType={ColorTypes.Tertiary}
                                    size='small'
                                    className={styles.mobileSignUpButton}
                                    onClick={() => setSignUpModalState(true)}
                                >
                                    Sign up
                                </Button>
                            </IonMenuToggle>
                        </>
                    )}
                </IonList>
                <IonMenuToggle>
                    <IonRouterLink routerLink='/' className={styles.logoContainer}>
                        <UprootLogoExtendedSVG />
                    </IonRouterLink>
                </IonMenuToggle>
            </IonMenu>
        </div>
    );
};

export default MobileNavigationComponent;
