import { ElectedOfficial } from '../../../@types/generated-gql-typed-hooks';
import { getClass, selectRepPhoto } from '../../../utils';
import { IonItem } from '@ionic/react';
import React from 'react';
import RepThumbnailImage from '../../RepThumbnailImage';
import styles from './styles.module.scss';

type Props = {
    rep: ElectedOfficial;
};

const MyRepCard: React.FC<Props> = ({ rep }) => {
    const { photoUrl, name, office, party, picture } = rep;
    const imageSrc = selectRepPhoto({ photoUrl, picture });
    const RepCard = (
        <IonItem className={styles.container}>
            <RepThumbnailImage imageSrc={imageSrc} rep={rep} />
            <div className={styles.text}>
                <span className={getClass(styles, 'name')}>{name}</span>
                <span className={styles.title}>{office}</span>
                <span className={styles.title}>{party}</span>
            </div>
        </IonItem>
    );

    return RepCard;
};

export default MyRepCard;
