import React from 'react';

/* eslint-disable */

const UprootLogoStackedSVG = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width='220' height='161' viewBox='0 0 220 161' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M38.3109 99H30.8413C29.8838 99 29.1061 99.7776 29.1061 100.735V127.249C29.1061 128.826 28.9527 130.243 28.6406 131.508C28.3285 132.772 27.8154 133.856 27.1012 134.761C26.387 135.671 25.4454 136.375 24.2763 136.872C23.1072 137.369 21.6894 137.618 20.023 137.618C18.309 137.618 16.8648 137.369 15.6957 136.867C14.5266 136.364 13.585 135.666 12.8708 134.756C12.1567 133.851 11.6435 132.767 11.3314 131.502C11.0193 130.238 10.8712 128.82 10.8712 127.244V100.735C10.8712 99.7776 10.0935 99 9.13601 99H1.73515C0.777645 99 0 99.7776 0 100.735V128.609C0 131.471 0.460239 134.095 1.3913 136.475C2.32235 138.861 3.65546 140.913 5.3959 142.627C7.13635 144.341 9.2471 145.669 11.7282 146.595C14.2039 147.526 16.9706 147.992 20.023 147.992C23.0754 147.992 25.8421 147.516 28.3179 146.558C30.799 145.606 32.9044 144.283 34.6502 142.585C36.3906 140.892 37.7237 138.856 38.6548 136.47C39.5805 134.089 40.0461 131.465 40.0461 128.603V100.735C40.0461 99.7776 39.2684 99 38.3109 99Z'
                fill='white'
            />
            <path
                d='M82.1712 123.282C81.5522 121.203 80.6212 119.372 79.3833 117.775C78.1401 116.177 76.5954 114.897 74.7333 113.945C72.8765 112.992 70.7287 112.511 68.2952 112.511C66.4384 112.511 64.6451 112.881 62.9311 113.622C61.508 114.235 60.3019 115.325 59.3021 116.468C59.0799 116.722 58.6726 116.584 58.6514 116.251C58.6249 115.796 58.5985 115.325 58.572 114.981C58.5667 114.897 58.5562 114.812 58.5509 114.727C58.4662 113.839 57.7203 113.156 56.8263 113.156H50.2243C49.2667 113.156 48.4891 113.934 48.4891 114.891V159.265C48.4891 160.222 49.2667 161 50.2243 161H56.985C57.9425 161 58.7202 160.222 58.7202 159.265V128.894C58.7202 127.942 58.8736 127.011 59.1857 126.106C59.4925 125.202 59.9475 124.392 60.5452 123.673C61.143 122.959 61.8784 122.382 62.7618 121.959C63.64 121.531 64.6557 121.314 65.8036 121.314C67.0891 121.314 68.1736 121.557 69.057 122.028C69.9405 122.504 70.6546 123.139 71.2048 123.927C71.755 124.71 72.157 125.63 72.4215 126.678C72.6807 127.731 72.813 128.799 72.813 129.899C72.813 132.714 72.083 134.967 70.6335 136.655C69.1787 138.348 67.1896 139.194 64.6609 139.194C64.4758 139.194 64.2959 139.194 64.1214 139.189C63.2749 139.168 62.5872 139.855 62.5872 140.702V146.214C62.5872 147.008 63.1956 147.669 63.9891 147.727C64.4917 147.764 65.026 147.78 65.5973 147.78C67.6446 147.78 69.7183 147.42 71.8185 146.706C73.9133 145.992 75.7966 144.897 77.4683 143.416C79.1347 141.94 80.4942 140.056 81.5417 137.766C82.5891 135.475 83.1128 132.783 83.1128 129.683C83.1022 127.487 82.7901 125.35 82.1712 123.282Z'
                fill='white'
            />
            <path
                d='M112.568 114.659C112.203 114.553 111.833 114.474 111.457 114.421C109.955 114.198 110.256 114.246 109.135 114.278C105.924 114.362 103.125 115.659 102.718 115.849C101.93 116.219 101.427 116.553 101.067 116.817C100.464 117.261 100.02 117.706 99.7237 118.034C99.7132 117.51 99.7026 116.992 99.6867 116.468L99.5439 114.749C99.4698 113.855 98.7186 113.162 97.8193 113.162H91.2754C90.3179 113.162 89.5403 113.939 89.5403 114.897V144.971C89.5403 145.929 90.3179 146.706 91.2754 146.706H98.105C99.0625 146.706 99.8401 145.929 99.8401 144.971V130.106C99.8401 128.841 100.009 127.979 100.343 127.265C100.824 126.244 101.464 125.652 102.088 125.096C102.67 124.578 104.194 123.255 106.611 122.79C107.23 122.668 108.701 122.403 110.463 122.895C110.468 122.895 110.473 122.901 110.479 122.901C111.203 123.107 111.949 122.663 112.081 121.922L113.208 115.701C113.293 115.241 113.018 114.786 112.568 114.659Z'
                fill='white'
            />
            <path
                d='M149.715 122.922C148.832 120.753 147.604 118.854 146.033 117.235C144.462 115.616 142.574 114.336 140.383 113.41C138.193 112.479 135.76 112.014 133.088 112.014C130.417 112.014 127.973 112.479 125.756 113.41C123.54 114.336 121.641 115.616 120.069 117.235C118.498 118.854 117.271 120.753 116.388 122.922C115.504 125.091 115.065 127.413 115.065 129.894C115.065 132.375 115.504 134.698 116.388 136.867C117.271 139.035 118.498 140.929 120.069 142.548C121.641 144.167 123.54 145.458 125.756 146.41C127.973 147.362 130.417 147.838 133.088 147.838C135.76 147.838 138.188 147.362 140.383 146.41C142.579 145.458 144.462 144.167 146.033 142.548C147.604 140.929 148.832 139.035 149.715 136.867C150.593 134.698 151.038 132.375 151.038 129.894C151.038 127.418 150.599 125.091 149.715 122.922ZM140.6 133.222C140.267 134.296 139.754 135.258 139.066 136.115C138.373 136.972 137.527 137.655 136.527 138.152C135.527 138.655 134.379 138.903 133.094 138.903C131.803 138.903 130.65 138.655 129.623 138.152C128.597 137.649 127.751 136.972 127.089 136.115C126.423 135.258 125.91 134.296 125.55 133.222C125.19 132.148 125.016 131.042 125.016 129.894C125.016 128.746 125.196 127.641 125.55 126.567C125.91 125.498 126.423 124.546 127.089 123.705C127.756 122.874 128.602 122.202 129.623 121.705C130.65 121.203 131.803 120.954 133.094 120.954C134.379 120.954 135.527 121.203 136.527 121.705C137.527 122.208 138.373 122.874 139.066 123.705C139.754 124.541 140.267 125.493 140.6 126.567C140.934 127.641 141.103 128.746 141.103 129.894C141.098 131.037 140.928 132.148 140.6 133.222Z'
                fill='white'
            />
            <path
                d='M189.449 122.922C188.566 120.753 187.338 118.854 185.767 117.235C184.196 115.616 182.308 114.336 180.117 113.41C177.922 112.479 175.489 112.014 172.822 112.014C170.151 112.014 167.707 112.479 165.49 113.41C163.274 114.336 161.375 115.616 159.809 117.235C158.232 118.854 157.005 120.753 156.122 122.922C155.243 125.091 154.799 127.413 154.799 129.894C154.799 132.375 155.238 134.698 156.122 136.867C157.005 139.035 158.232 140.929 159.809 142.548C161.38 144.167 163.274 145.458 165.49 146.41C167.707 147.362 170.151 147.838 172.822 147.838C175.494 147.838 177.922 147.362 180.117 146.41C182.308 145.458 184.191 144.167 185.767 142.548C187.344 140.929 188.566 139.035 189.449 136.867C190.327 134.698 190.772 132.375 190.772 129.894C190.772 127.418 190.333 125.091 189.449 122.922ZM180.329 133.222C179.996 134.296 179.483 135.258 178.795 136.115C178.102 136.972 177.255 137.655 176.256 138.152C175.256 138.655 174.108 138.903 172.822 138.903C171.532 138.903 170.378 138.655 169.357 138.152C168.331 137.649 167.485 136.972 166.818 136.115C166.152 135.258 165.638 134.296 165.279 133.222C164.919 132.148 164.744 131.042 164.744 129.894C164.744 128.746 164.924 127.641 165.279 126.567C165.638 125.498 166.152 124.546 166.818 123.705C167.485 122.874 168.331 122.202 169.357 121.705C170.378 121.203 171.537 120.954 172.822 120.954C174.108 120.954 175.256 121.203 176.256 121.705C177.255 122.208 178.102 122.874 178.795 123.705C179.483 124.541 179.996 125.493 180.329 126.567C180.662 127.641 180.832 128.746 180.832 129.894C180.832 131.037 180.668 132.148 180.329 133.222Z'
                fill='white'
            />
            <path
                d='M219.777 140.66C219.772 140.242 219.687 139.676 219.285 139.321C218.799 138.893 218.106 139.02 217.386 139.088C216.63 139.162 214.08 139.427 212.239 138.422C211.874 138.226 211.16 137.824 210.625 136.983C209.975 135.962 209.985 134.915 210.012 134.465V119.266C210.012 119.06 210.181 118.896 210.382 118.896H217.153C217.989 118.896 218.666 118.219 218.666 117.383V111.744C218.666 110.908 217.989 110.231 217.153 110.231H210.382C210.176 110.231 210.012 110.062 210.012 109.861V100.73C210.012 99.894 209.335 99.2169 208.499 99.2169H201.225C200.389 99.2169 199.712 99.894 199.712 100.73V109.861C199.712 110.067 199.543 110.231 199.342 110.231H194.173C193.337 110.231 192.66 110.908 192.66 111.744V117.388C192.66 118.224 193.337 118.901 194.173 118.901H199.342C199.548 118.901 199.712 119.071 199.712 119.272V133.899C199.712 135.999 199.924 137.903 200.357 139.623C200.786 141.337 201.5 142.807 202.5 144.019C203.505 145.235 204.812 146.188 206.43 146.881C208.181 147.632 209.652 147.732 212.44 147.918C213.138 147.965 214.075 147.97 215.942 147.992C216.852 148.002 217.667 148.008 218.37 148.008C219.148 148.008 219.777 147.378 219.777 146.6V140.66Z'
                fill='white'
            />
            <g clipPath='url(#clip0)'>
                <path
                    d='M134.739 69.9247C117.384 82.2049 93.3607 78.0941 81.0753 60.7393C68.7951 43.3844 72.9058 19.3607 90.2607 7.07529C107.616 -5.20491 131.639 -1.09415 143.925 16.2607C156.205 33.6155 152.094 57.6393 134.739 69.9247Z'
                    fill='#B64C00'
                />
                <path
                    d='M121.563 25.6806C121.422 25.7483 121.276 25.8316 121.146 25.9411L117.275 29.1869L112.325 22.0908C111.862 21.4239 112.023 20.5017 112.695 20.0328L114.8 18.5636C115.467 18.0999 116.389 18.2614 116.858 18.9335L121.563 25.6806Z'
                    fill='white'
                />
                <path
                    d='M115.097 31.0157L110.783 34.6263L104.333 25.3992C103.869 24.7323 104.036 23.8101 104.703 23.3412L106.876 21.8199C107.543 21.3562 108.465 21.5229 108.934 22.1898L115.097 31.0157Z'
                    fill='white'
                />
                <path
                    d='M111.065 42.8999C110.971 43.0041 110.867 43.0927 110.747 43.1761L109.694 43.9107C108.736 44.5828 107.402 44.3431 106.73 43.3845L98.5448 31.667C98.1384 31.0834 98.2843 30.2707 98.8678 29.8643L101.29 28.171C101.874 27.7646 102.682 27.9105 103.093 28.494L108.637 36.429L108.246 36.7572C107.49 37.3929 107.392 38.5234 108.027 39.2789L111.065 42.8999Z'
                    fill='white'
                />
                <path
                    d='M103.802 48.9488L103.76 48.9749C102.52 49.8398 100.801 49.5324 99.9359 48.2924L94.5799 40.6231C94.0537 39.8676 94.2412 38.8204 94.9915 38.2942L96.7942 37.0334C97.5496 36.5071 98.5969 36.6947 99.1231 37.445L104.479 45.1246C105.344 46.3594 105.037 48.084 103.802 48.9488Z'
                    fill='white'
                />
                <path
                    d='M128.633 35.2202C129.248 36.3196 130.123 37.893 130.545 39.8989C130.701 40.6439 130.8 41.389 130.837 42.1288C130.899 43.2855 130.816 44.4369 130.582 45.5727C130.207 47.4327 129.467 49.2146 128.362 50.913C127.263 52.6115 125.809 54.1016 124.017 55.3729C122.225 56.6441 120.339 57.5194 118.369 57.9988C116.4 58.4781 114.472 58.5875 112.591 58.327C111.445 58.1707 110.335 57.8685 109.257 57.4257C109.001 57.3215 108.173 56.9724 107.251 56.4149C105.646 55.4406 104.469 54.2475 103.724 53.3774C104.146 52.7209 104.797 51.7883 105.698 50.7672C106.897 49.4073 108.647 47.7609 112.049 45.7446C117.728 42.3789 120.417 42.4675 121.302 40.0187C121.771 38.7214 121.474 37.4345 121.214 36.653C119.687 37.7315 116.488 39.9979 114.967 41.0764C114.227 41.6026 113.493 41.8423 112.966 41.1024L111.341 39.1174C110.815 38.3776 110.992 37.346 111.732 36.8249C113.289 35.5537 115.217 33.8396 116.785 32.5423C118.364 31.1303 120.005 29.8174 121.678 28.5201C122.407 27.9991 123.428 28.171 123.954 28.89L125.908 31.3439L125.913 31.3491C127.174 32.808 128.044 34.173 128.633 35.2202Z'
                    fill='white'
                />
            </g>
            <defs>
                <clipPath id='clip0'>
                    <rect width='77' height='77' fill='white' transform='translate(74)' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default UprootLogoStackedSVG;
