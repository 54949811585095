import { ApolloError } from '@apollo/client';
import { authedUserFieldsObj, useAuthenticatedUser } from '../AuthenticatedUser';
import { useDeleteUserMutation } from '../../@types/generated-gql-typed-hooks';
import DeleteProfileForm from './DeleteProfileForm';
import React, { useState } from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';

type Props = {
    onDeleteProfileComplete: () => void;
};

const DeleteProfileFormContainer: React.FC<Props> = ({ onDeleteProfileComplete = () => true }) => {
    const [, setErrorState] = useState<ApolloError>();
    const [, updateAuthenticatedUser] = useAuthenticatedUser();
    const [, , removeStoredValue] = useLocalStorage('token');
    const [deleteUser] = useDeleteUserMutation({
        onError: (err) => {
            setErrorState(err);
        },
        onCompleted: () => {
            onDeleteProfileComplete();
        },
    });

    const onSignOut = () => {
        removeStoredValue();
        updateAuthenticatedUser({ ...authedUserFieldsObj });
    };

    const handleSubmit = (): void => {
        deleteUser();
        onSignOut();
    };

    const handleCancel = () => onDeleteProfileComplete();

    return <DeleteProfileForm handleCancel={handleCancel} handleSubmit={handleSubmit} />;
};

export default DeleteProfileFormContainer;
