import AboutUs from './components/AboutUs';
import Account from './components/Account';
import FAQs from './components/FAQs';
import Home from './components/Home';
import MyReps from './components/MyReps';
import RepProfilePage from './components/RepProfilePage';
// Import other components

interface RouteConfig {
    path: string;
    component: React.FC<any>;
    exact: boolean;
    auth?: boolean;
    name?: string;
}

const routes: RouteConfig[] = [
    {
        path: '/',
        component: Home,
        exact: true,
        name: 'Home',
    },
    {
        path: '/my-reps',
        component: MyReps,
        exact: true,
        name: 'My Reps',
    },
    {
        path: '/my-reps/:id',
        component: RepProfilePage,
        exact: true,
    },
    {
        path: '/faqs',
        component: FAQs,
        exact: true,
        name: 'FAQs',
    },
    {
        path: '/about-us',
        component: AboutUs,
        exact: true,
        name: 'About Us',
    },
    {
        path: '/account',
        component: Account,
        exact: true,
        auth: true,
        name: 'Account',
    },
];

export default routes;
