import React from 'react';
import styles from './styles.module.scss';

type Props = {
    resetEmail: boolean;
    handleResendEmailLink: () => void;
};

const EmailVerification: React.FC<Props> = ({ resetEmail, handleResendEmailLink }) => (
    <div className={styles.container}>
        {!resetEmail ? (
            <React.Fragment>
                <p>Thanks for signing up! We emailed you a link to confirm your account.</p>
                <p>
                    Not in your inbox?{' '}
                    <span className={styles.resendLink} onClick={handleResendEmailLink}>
                        Resend email.
                    </span>
                </p>
            </React.Fragment>
        ) : (
            <p>We just sent a link to reset your password!</p>
        )}
    </div>
);

export default EmailVerification;
