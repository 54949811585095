import EmailInput from '../../shared/EmailInput';
import Form from '../../shared/Form';
import React from 'react';
import styles from './styles.module.scss';
import { FieldValues, SubmitHandler } from 'react-hook-form';

type SignUpNewsletterComponentProps = {
    onSubmit: SubmitHandler<FieldValues>;
};

const SignUpNewsletterComponent: React.FC<SignUpNewsletterComponentProps> = ({ onSubmit }) => {
    return (
        <React.Fragment>
            <h2>Sign up to receive the latest updates</h2>
            <Form className={styles.subscriberForm} onSubmit={onSubmit} submitText='Join our Newsletter' resetOnSuccess={true}>
                <EmailInput name='email' label='Email' required showExample={false} />
            </Form>
        </React.Fragment>
    );
};

export default SignUpNewsletterComponent;
