import {
    businessOutline,
    chevronDownOutline,
    chevronUpOutline,
    closeOutline,
    libraryOutline,
    locationOutline,
    mapOutline,
    peopleOutline,
    podiumOutline,
    schoolOutline,
} from 'ionicons/icons';
import { ElectedOfficial, JurisdictionEnum, JurisdictionLabel } from '../../@types/generated-gql-typed-hooks';
import { getClass, getDeviceViewType } from '../../utils';
import { InputStyleTypes } from '../shared/Input/types';
import { IonIcon, IonLoading, IonRouterLink } from '@ionic/react';
import { JurisdictionItem } from './types';
import _startCase from 'lodash/startCase';
import AddressAutocomplete from '../shared/AddressAutocomplete';
import cn from 'classnames';
import Header from '../Header';
import MyRepCard from './MyRepCard';
import PageLayout from '../PageLayout';
import React, { useEffect, useState } from 'react';
import RepInfoModal from './MyRepInfoModal';
import styles from './myreps.module.scss';
import Button, { ColorTypes } from '../shared/Button';
import VoteActionButton from './VoteActionButton';

type Props = {
    repsData: JurisdictionItem[];
    userAddress?: string | undefined;
    handlePlaceSelected: (address: string) => void;
    onAddressChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onLoading?: boolean;
    jurisdictionLabels: JurisdictionLabel[];
    stateName?: string;
};

const MyRepsComponent: React.FC<Props> = ({
    repsData,
    handlePlaceSelected,
    userAddress,
    onAddressChange,
    onLoading = false,
    jurisdictionLabels,
    stateName,
}) => {
    const [jurisdictionState, setJurisdictionState] = useState<string>('');
    const [currentRep, setCurrentRep] = useState<ElectedOfficial>();
    const [repInfoModalOpen, setRepInfoModalOpen] = useState<boolean>(false);
    const [inputIsDisplayed, setInputIsDisplayed] = useState<boolean>(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        setInputIsDisplayed(!userAddress);
    }, [userAddress]);

    const startCasedJurisdiction = (jurisdiction: JurisdictionEnum): string => {
        const jurisdictionName = jurisdiction?.toLocaleLowerCase() as string;
        return _startCase(jurisdictionName);
    };

    const onPlaceSelected = (address: string): void => {
        handlePlaceSelected(address);
        setInputIsDisplayed(false);
    };

    const onToggle = () => {
        setInputIsDisplayed(true);
    };

    const openRepInfoModal = (rep: ElectedOfficial) => {
        setCurrentRep(rep);
        setRepInfoModalOpen(true);
    };

    function scrollToPoint(id: string) {
        const element = document.getElementById(id);
        const content = element?.closest('ion-content');
        const position = element?.getBoundingClientRect();
        const offset = getDeviceViewType().includes('hybrid') ? 220 : 175;
        content?.scrollByPoint(position?.left || 0, Math.ceil(position?.top ? position?.top - offset : 0), 1000);
    }

    const toggleIcon = <IonIcon icon={closeOutline} />;

    // get lowest jurisdiction for default active mobile nav
    useEffect(() => {
        const jurisdictions: JurisdictionEnum[] = repsData.map(({ jurisdiction }) => jurisdiction);
        const defaultJurisdiction: string = startCasedJurisdiction(jurisdictions[0]);
        setJurisdictionState(defaultJurisdiction);
    }, [repsData]);

    return (
        <PageLayout>
            <IonLoading isOpen={onLoading} message='Please wait...' />
            <Header
                pageTitle='My representatives'
                subline={
                    <div className={cn(inputIsDisplayed && styles.inputWrapper)}>
                        <AddressAutocomplete
                            toggleIcon={toggleIcon}
                            onToggle={onToggle}
                            selectedAddress={userAddress}
                            placeholder="What's your address?"
                            styleType={InputStyleTypes.Borderless}
                            onPlaceSelected={onPlaceSelected}
                        />

                        {/* Display jurisdiction labels below the address */}
                        {!inputIsDisplayed && jurisdictionLabels.length > 0 && (
                            <div>
                                <div>
                                    <Button
                                        className={getClass(styles, 'jurisdictionButton')}
                                        size={'small'}
                                        colorType={ColorTypes.Tertiary}
                                        onClick={toggleExpand}
                                    >
                                        {isExpanded ? 'Hide Address Info' : 'Show Address Info'}
                                        <IonIcon icon={isExpanded ? chevronUpOutline : chevronDownOutline} />
                                    </Button>
                                </div>

                                {isExpanded && (
                                    <div className={getClass(styles, 'jurisdictionLabels')}>
                                        {jurisdictionLabels.map((label, i) => (
                                            <div key={i} className={getClass(styles, 'jurisdictionLabel')}>
                                                <span className={getClass(styles, 'labelIcon')}>
                                                    {label.type === 'congressional' && <IonIcon icon={businessOutline} />}
                                                    {label.type === 'stateSenate' && <IonIcon icon={podiumOutline} />}
                                                    {label.type === 'stateAssembly' && <IonIcon icon={libraryOutline} />}
                                                    {label.type === 'county' && <IonIcon icon={mapOutline} />}
                                                    {label.type === 'cityCouncil' && <IonIcon icon={peopleOutline} />}
                                                    {label.type === 'schoolDistrict' && <IonIcon icon={schoolOutline} />}
                                                    {label.type === 'city' && <IonIcon icon={locationOutline} />}
                                                </span>
                                                <span className={getClass(styles, 'labelText')}>{`${_startCase(label.type)}: ${label.label}`}</span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                }
                changeAddress={onAddressChange}
                isMobileSticky
            >
                <div className={getClass(styles, 'jurisdictionMobileNav')}>
                    {!isExpanded &&
                        repsData.map(({ jurisdiction }, i) => {
                            const casedJurisdiction = startCasedJurisdiction(jurisdiction);
                            const isJurisdiction = jurisdictionState === casedJurisdiction;

                            return (
                                <IonRouterLink
                                    key={i}
                                    className={cn(styles.navTitle, { [styles.active]: isJurisdiction })}
                                    onClick={() => {
                                        if (!isJurisdiction) {
                                            setJurisdictionState(casedJurisdiction);
                                            scrollToPoint(casedJurisdiction);
                                        }
                                    }}
                                >
                                    {casedJurisdiction}
                                </IonRouterLink>
                            );
                        })}
                </div>
            </Header>
            <div className={getClass(styles, 'container')}>
                <div className={`${getClass(styles, 'contentSection')} iosMyrepsContentSection ion-padding-start ion-padding-end`}>
                    {repsData.map(({ jurisdiction, repsList }, i) => {
                        return (
                            <React.Fragment key={i}>
                                <h3 className={`${getClass(styles, 'sectionHeader')} uproot-h3`} id={startCasedJurisdiction(jurisdiction)}>
                                    {startCasedJurisdiction(jurisdiction)}
                                </h3>
                                <div key={i} className={getClass(styles, 'repCardsContainer')}>
                                    {repsList.map((rep, i) => {
                                        return (
                                            <div key={i} className={getClass(styles, 'card')} onClick={() => openRepInfoModal(rep)}>
                                                <MyRepCard rep={rep} />
                                            </div>
                                        );
                                    })}
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
            <VoteActionButton stateName={stateName} />
            <RepInfoModal rep={currentRep} isOpen={repInfoModalOpen} setIsOpen={setRepInfoModalOpen} />
        </PageLayout>
    );
};

export default MyRepsComponent;
