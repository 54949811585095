import { Redirect, RouteProps } from 'react-router-dom';
import { useAuthenticatedUser } from '../AuthenticatedUser';
import React from 'react';
import NamedRoute from '../shared/NamedRoute';

interface Props extends RouteProps {
    name?: string;
}

const AuthRoute: React.FC<Props> = ({ component, ...rest }) => {
    const [{ isAuthenticated, loading }] = useAuthenticatedUser();

    return loading || isAuthenticated ? <NamedRoute component={component} {...rest} /> : <Redirect to='/' />;
};

export default AuthRoute;
