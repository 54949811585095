import { UseDialogProps } from '../../ModalsProvider';
import EmailVerification from '../EmailVerificationContainer';
import Modal, { HeightType } from '../../shared/Modal';
import React from 'react';

type Props = UseDialogProps;

const EmailVerificationModal: React.FC<Props> = ({ setIsOpen, ...props }) => (
    <Modal title='Check your email' height={HeightType.Short} setIsOpen={setIsOpen} {...props}>
        <EmailVerification />
    </Modal>
);

export default EmailVerificationModal;
