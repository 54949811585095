import React from 'react';

/* eslint-disable */

const UprootLogoExtendedSVG = ({ width, ...props }: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width={width || '100'} height='100%' viewBox='0 0 251 77' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M120.542 21H115.172C114.484 21 113.925 21.5591 113.925 22.2474V41.3086C113.925 42.4419 113.814 43.4611 113.59 44.3701C113.366 45.279 112.997 46.0587 112.483 46.709C111.97 47.3631 111.293 47.8689 110.452 48.2264C109.612 48.5839 108.593 48.7627 107.395 48.7627C106.163 48.7627 105.124 48.5839 104.284 48.2226C103.443 47.8613 102.766 47.3593 102.253 46.7052C101.74 46.0549 101.371 45.2752 101.146 44.3663C100.922 43.4573 100.815 42.4381 100.815 41.3048V22.2474C100.815 21.5591 100.256 21 99.568 21H94.2474C93.5591 21 93 21.5591 93 22.2474V42.286C93 44.3435 93.3309 46.2298 94.0002 47.9412C94.6696 49.6564 95.6279 51.132 96.8792 52.3642C98.1304 53.5964 99.6478 54.551 101.431 55.2165C103.211 55.8859 105.2 56.2206 107.395 56.2206C109.589 56.2206 111.578 55.8783 113.358 55.1899C115.142 54.5054 116.655 53.5546 117.91 52.3338C119.162 51.1168 120.12 49.6526 120.789 47.9374C121.455 46.226 121.79 44.3397 121.79 42.2822V22.2474C121.79 21.5591 121.23 21 120.542 21Z'
                fill='#434849'
            />
            <path
                d='M152.074 38.4563C151.629 36.9616 150.959 35.6458 150.069 34.4972C149.176 33.3487 148.065 32.4283 146.726 31.7438C145.392 31.0592 143.848 30.7131 142.098 30.7131C140.763 30.7131 139.474 30.9794 138.242 31.5118C137.219 31.9529 136.352 32.7364 135.633 33.5579C135.473 33.7404 135.18 33.6415 135.165 33.4019C135.146 33.0749 135.127 32.7364 135.108 32.4892C135.104 32.4283 135.097 32.3675 135.093 32.3066C135.032 31.6677 134.496 31.1771 133.853 31.1771H129.107C128.418 31.1771 127.859 31.7362 127.859 32.4245V64.325C127.859 65.0133 128.418 65.5724 129.107 65.5724H133.967C134.655 65.5724 135.214 65.0133 135.214 64.325V42.4914C135.214 41.8068 135.325 41.1374 135.549 40.4871C135.77 39.8368 136.097 39.2549 136.527 38.7377C136.956 38.2243 137.485 37.8097 138.12 37.5055C138.751 37.1974 139.482 37.0415 140.307 37.0415C141.231 37.0415 142.011 37.2164 142.646 37.5549C143.281 37.8972 143.794 38.3536 144.19 38.9202C144.585 39.4831 144.874 40.1448 145.065 40.8979C145.251 41.6547 145.346 42.4229 145.346 43.2139C145.346 45.2372 144.821 46.8573 143.779 48.0705C142.733 49.2875 141.303 49.896 139.485 49.896C139.352 49.896 139.223 49.896 139.097 49.8922C138.489 49.877 137.995 50.3714 137.995 50.9799V54.9427C137.995 55.5132 138.432 55.9886 139.002 56.0304C139.364 56.057 139.748 56.0684 140.159 56.0684C141.63 56.0684 143.121 55.8098 144.631 55.2964C146.137 54.783 147.491 53.9957 148.693 52.9309C149.891 51.8698 150.868 50.5159 151.621 48.8692C152.374 47.2224 152.751 45.2866 152.751 43.058C152.743 41.4797 152.519 39.9433 152.074 38.4563Z'
                fill='#434849'
            />
            <path
                d='M173.926 32.2572C173.664 32.1811 173.398 32.1241 173.128 32.086C172.048 31.9263 172.264 31.9605 171.458 31.9834C169.15 32.0442 167.138 32.976 166.845 33.1129C166.278 33.3791 165.917 33.6187 165.658 33.8089C165.225 34.1283 164.905 34.4478 164.692 34.6836C164.685 34.3071 164.677 33.9344 164.666 33.5578L164.563 32.3218C164.51 31.6791 163.97 31.1809 163.323 31.1809H158.619C157.93 31.1809 157.371 31.74 157.371 32.4283V54.049C157.371 54.7373 157.93 55.2964 158.619 55.2964H163.529C164.217 55.2964 164.776 54.7373 164.776 54.049V43.3623C164.776 42.4533 164.898 41.8334 165.137 41.32C165.483 40.586 165.944 40.16 166.392 39.7607C166.811 39.388 167.906 38.4372 169.644 38.1026C170.089 38.0151 171.146 37.8249 172.413 38.1786C172.416 38.1786 172.42 38.1824 172.424 38.1824C172.945 38.3307 173.481 38.0113 173.576 37.4789L174.386 33.0064C174.447 32.6755 174.25 32.3485 173.926 32.2572Z'
                fill='#434849'
            />
            <path
                d='M200.632 38.1976C199.997 36.6384 199.114 35.273 197.985 34.1093C196.855 32.9455 195.498 32.0252 193.923 31.3596C192.349 30.6903 190.599 30.3556 188.679 30.3556C186.758 30.3556 185.001 30.6903 183.407 31.3596C181.814 32.0252 180.449 32.9455 179.319 34.1093C178.19 35.273 177.307 36.6384 176.672 38.1976C176.037 39.7569 175.721 41.4265 175.721 43.2101C175.721 44.9938 176.037 46.6633 176.672 48.2226C177.307 49.7819 178.19 51.1434 179.319 52.3071C180.449 53.4709 181.814 54.3989 183.407 55.0834C185.001 55.768 186.758 56.1102 188.679 56.1102C190.599 56.1102 192.345 55.768 193.923 55.0834C195.501 54.3989 196.855 53.4709 197.985 52.3071C199.114 51.1434 199.997 49.7819 200.632 48.2226C201.263 46.6633 201.582 44.9938 201.582 43.2101C201.582 41.4303 201.267 39.7569 200.632 38.1976ZM194.079 45.6023C193.839 46.3743 193.47 47.0665 192.976 47.6826C192.478 48.2987 191.869 48.7893 191.151 49.1468C190.432 49.5081 189.607 49.6868 188.682 49.6868C187.754 49.6868 186.925 49.5081 186.188 49.1468C185.45 48.7855 184.841 48.2987 184.366 47.6826C183.887 47.0665 183.518 46.3743 183.259 45.6023C183.001 44.8302 182.875 44.0354 182.875 43.2101C182.875 42.3848 183.004 41.59 183.259 40.818C183.518 40.0497 183.887 39.3652 184.366 38.7605C184.845 38.1634 185.454 37.6804 186.188 37.3229C186.925 36.9616 187.754 36.7829 188.682 36.7829C189.607 36.7829 190.432 36.9616 191.151 37.3229C191.869 37.6842 192.478 38.1634 192.976 38.7605C193.47 39.3614 193.839 40.0459 194.079 40.818C194.319 41.59 194.44 42.3848 194.44 43.2101C194.436 44.0316 194.315 44.8302 194.079 45.6023Z'
                fill='#434849'
            />
            <path
                d='M229.197 38.1976C228.562 36.6384 227.679 35.273 226.55 34.1093C225.42 32.9455 224.063 32.0252 222.488 31.3596C220.91 30.6903 219.16 30.3556 217.244 30.3556C215.323 30.3556 213.566 30.6903 211.973 31.3596C210.379 32.0252 209.014 32.9455 207.888 34.1093C206.755 35.273 205.872 36.6384 205.237 38.1976C204.606 39.7569 204.286 41.4265 204.286 43.2101C204.286 44.9938 204.602 46.6633 205.237 48.2226C205.872 49.7819 206.755 51.1434 207.888 52.3071C209.018 53.4709 210.379 54.3989 211.973 55.0834C213.566 55.768 215.323 56.1102 217.244 56.1102C219.164 56.1102 220.91 55.768 222.488 55.0834C224.063 54.3989 225.417 53.4709 226.55 52.3071C227.683 51.1434 228.562 49.7819 229.197 48.2226C229.828 46.6633 230.148 44.9938 230.148 43.2101C230.148 41.4303 229.832 39.7569 229.197 38.1976ZM222.64 45.6023C222.401 46.3743 222.032 47.0665 221.537 47.6826C221.039 48.2987 220.431 48.7893 219.712 49.1468C218.993 49.5081 218.168 49.6868 217.244 49.6868C216.316 49.6868 215.487 49.5081 214.753 49.1468C214.015 48.7855 213.406 48.2987 212.927 47.6826C212.448 47.0665 212.079 46.3743 211.82 45.6023C211.562 44.8302 211.436 44.0354 211.436 43.2101C211.436 42.3848 211.566 41.59 211.82 40.818C212.079 40.0497 212.448 39.3652 212.927 38.7605C213.406 38.1634 214.015 37.6804 214.753 37.3229C215.487 36.9616 216.32 36.7829 217.244 36.7829C218.168 36.7829 218.993 36.9616 219.712 37.3229C220.431 37.6842 221.039 38.1634 221.537 38.7605C222.032 39.3614 222.401 40.0459 222.64 40.818C222.88 41.59 223.002 42.3848 223.002 43.2101C223.002 44.0316 222.884 44.8302 222.64 45.6023Z'
                fill='#434849'
            />
            <path
                d='M251 50.9494C250.996 50.649 250.935 50.2421 250.646 49.9873C250.296 49.6792 249.798 49.7705 249.281 49.8199C248.737 49.8732 246.904 50.0633 245.581 49.3407C245.318 49.2 244.805 48.911 244.421 48.3063C243.953 47.5723 243.96 46.8193 243.979 46.496V35.5697C243.979 35.4214 244.101 35.3035 244.246 35.3035H249.114C249.715 35.3035 250.201 34.8167 250.201 34.2158V30.1617C250.201 29.5608 249.715 29.074 249.114 29.074H244.246C244.097 29.074 243.979 28.9523 243.979 28.8078V22.2436C243.979 21.6427 243.493 21.1559 242.892 21.1559H237.663C237.062 21.1559 236.575 21.6427 236.575 22.2436V28.8078C236.575 28.9561 236.453 29.074 236.309 29.074H232.593C231.992 29.074 231.505 29.5608 231.505 30.1617V34.2196C231.505 34.8205 231.992 35.3073 232.593 35.3073H236.309C236.457 35.3073 236.575 35.429 236.575 35.5735V46.0891C236.575 47.5989 236.727 48.968 237.039 50.204C237.347 51.4362 237.86 52.4935 238.579 53.3644C239.302 54.2391 240.241 54.9237 241.405 55.4219C242.664 55.9619 243.721 56.0342 245.725 56.1673C246.227 56.2015 246.9 56.2053 248.243 56.2205C248.897 56.2281 249.483 56.232 249.988 56.232C250.547 56.232 251 55.7794 251 55.2203V50.9494Z'
                fill='#434849'
            />
            <g clipPath='url(#clip1)'>
                <path
                    d='M60.7383 69.9247C43.3835 82.2049 19.3597 78.0941 7.0743 60.7393C-5.2059 43.3844 -1.09513 19.3607 16.2597 7.07528C33.6145 -5.20492 57.6383 -1.09415 69.9237 16.2607C82.2039 33.6155 78.0931 57.6393 60.7383 69.9247Z'
                    fill='#434849'
                />
                <path
                    d='M47.562 25.6806C47.4213 25.7483 47.2754 25.8316 47.1452 25.9411L43.2741 29.1869L38.3245 22.0908C37.8608 21.4239 38.0223 20.5017 38.6944 20.0328L40.7993 18.5636C41.4662 18.0999 42.3884 18.2614 42.8573 18.9335L47.562 25.6806Z'
                    fill='white'
                />
                <path
                    d='M41.0963 31.0157L36.7823 34.6263L30.3322 25.3992C29.8685 24.7323 30.0352 23.8101 30.7021 23.3412L32.8747 21.8199C33.5416 21.3562 34.4638 21.5229 34.9327 22.1898L41.0963 31.0157Z'
                    fill='white'
                />
                <path
                    d='M37.0636 42.8999C36.9699 43.0041 36.8657 43.0927 36.7458 43.1761L35.6934 43.9107C34.7347 44.5828 33.4009 44.3431 32.7288 43.3845L24.5438 31.667C24.1374 31.0834 24.2833 30.2707 24.8668 29.8643L27.2895 28.171C27.873 27.7646 28.6806 27.9105 29.0922 28.494L34.6357 36.429L34.245 36.7572C33.4895 37.3929 33.3905 38.5234 34.0262 39.2789L37.0636 42.8999Z'
                    fill='white'
                />
                <path
                    d='M29.8008 48.9488L29.7591 48.9749C28.5191 49.8398 26.7998 49.5324 25.9349 48.2924L20.5789 40.6231C20.0527 39.8676 20.2403 38.8204 20.9905 38.2942L22.7932 37.0334C23.5487 36.5071 24.5959 36.6947 25.1221 37.4449L30.4781 45.1246C31.343 46.3594 31.0356 48.084 29.8008 48.9488Z'
                    fill='white'
                />
                <path
                    d='M54.6321 35.2203C55.2469 36.3196 56.1222 37.893 56.5442 39.8989C56.7005 40.644 56.7995 41.389 56.836 42.1288C56.8985 43.2855 56.8151 44.4369 56.5807 45.5727C56.2055 47.4327 55.4657 49.2146 54.3612 50.9131C53.2618 52.6115 51.8082 54.1016 50.016 55.3729C48.2237 56.6442 46.3376 57.5195 44.3682 57.9988C42.3988 58.4781 40.4711 58.5875 38.5902 58.327C37.444 58.1707 36.3342 57.8685 35.2557 57.4257C35.0005 57.3215 34.172 56.9724 33.2499 56.4149C31.6452 55.4406 30.4677 54.2475 29.7226 53.3774C30.1446 52.721 30.7959 51.7884 31.6973 50.7672C32.8956 49.4073 34.6462 47.7609 38.0484 45.7446C43.7274 42.3789 46.4158 42.4675 47.3015 40.0187C47.7704 38.7214 47.4734 37.4345 47.2129 36.653C45.6864 37.7315 42.4874 39.9979 40.966 41.0764C40.2262 41.6026 39.4916 41.8423 38.9653 41.1024L37.3398 39.1174C36.8136 38.3776 36.9907 37.346 37.7305 36.825C39.2884 35.5537 41.2161 33.8396 42.7843 32.5423C44.363 31.1303 46.0042 29.8174 47.6766 28.5201C48.406 27.9991 49.4272 28.171 49.9534 28.89L51.9072 31.3439L51.9124 31.3492C53.1733 32.808 54.0434 34.173 54.6321 35.2203Z'
                    fill='white'
                />
            </g>
            <defs>
                <clipPath id='clip1'>
                    <rect width='77' height='77' fill='white' transform='translate(-0.000976562)' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default UprootLogoExtendedSVG;
