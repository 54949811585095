import Form from '../Form';
import PasswordInput from '../PasswordInput';
import React from 'react';
import styles from './styles.module.scss';
import { FieldValues, SubmitHandler } from 'react-hook-form';

type Props = {
    submitBtnText: string;
    handleSubmit: SubmitHandler<FieldValues>;
    disabled?: boolean;
    reset?: boolean;
};

const SetPasswordFormComponent: React.FC<Props> = ({ handleSubmit, submitBtnText, disabled, reset }) => {
    const onSubmitHelper = (data: any) => {
        handleSubmit(data);
    };

    return (
        <Form
            submitText={submitBtnText}
            onSubmit={onSubmitHelper}
            className={styles.resetPasswordForm}
            render={({ watch }) => {
                return (
                    <>
                        {!reset && <PasswordInput name='oldPassword' label='Old password' disabled={disabled} />}
                        <PasswordInput name='newPassword' label='New password' disabled={disabled} />
                        <PasswordInput
                            name='confirmPassword'
                            label='Confirm password'
                            disabled={disabled}
                            registerOptions={{
                                validate: (value) => {
                                    return value === watch('newPassword', false) || "Passwords don't match";
                                },
                            }}
                        />
                    </>
                );
            }}
        />
    );
};

export default SetPasswordFormComponent;
