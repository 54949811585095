import { ApolloError } from '@apollo/client';
import get from 'lodash/get';
import { useToast } from '../WithToasterComponent';

export const useToasterWithError = () => {
    const { presentErrorToast } = useToast();

    return (error: string | ApolloError, duration = 6000) => {
        let errorMessage = 'An error has occurred';
        if (error instanceof ApolloError) {
            errorMessage = get(error, 'graphQLErrors[0].extensions.exception.data.message[0].messages[0].message', (error as ApolloError).message);
        }

        presentErrorToast(errorMessage, { duration });
    };
};