import { IonRouterLink } from '@ionic/react';
import cn from 'classnames';
import React, { MouseEvent } from 'react';
import styles from './styles.module.scss';

export enum ColorTypes {
    Primary = 'primary',
    Secondary = 'secondary',
    Tertiary = 'tertiary',
}

type LinkProps = {
    children: React.ReactNode;
    className?: string;
    colorType: ColorTypes;
    href?: string | undefined;
    onClick?: ((event: MouseEvent<HTMLIonRouterLinkElement> | any) => void) | undefined;
    shouldStartCaseText?: boolean;
};

const Link: React.FC<LinkProps> = ({ shouldStartCaseText = false, className, children, colorType, href, onClick }) => {
    const classNamesObj = {
        [styles.link]: true,
        [styles[colorType]]: !!colorType,
    };

    return !href ? (
        <span color={colorType} className={cn(classNamesObj, className, shouldStartCaseText && styles.capitalized)} onClick={onClick}>
            {children}
        </span>
    ) : (
        <IonRouterLink href={href}>
            <span color={colorType} className={cn(classNamesObj, className, shouldStartCaseText && styles.capitalized)}>
                {children}
            </span>
        </IonRouterLink>
    );
};

export default Link;
