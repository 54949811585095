import React, { FC } from 'react';
import styles from './styles.module.scss';

const InputErrorMessage: FC = () => {
    return (
        <div className={styles.errorMessage}>
            <p>Please check your entry and try again.</p>
        </div>
    );
};

export default InputErrorMessage;
