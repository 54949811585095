import { useForgotPasswordMutation } from '../../@types/generated-gql-typed-hooks';
import { useToasterWithError } from '../WithToaster';
import ForgotPasswordForm from './ForgotPasswordForm';
import React from 'react';

type Props = {
    onForgotPasswordComplete: () => void;
};

const ForgotPasswordFormContainer: React.FC<Props> = ({ onForgotPasswordComplete = () => true }) => {
    const setToasterWithError = useToasterWithError();

    const [forgotPassword] = useForgotPasswordMutation({
        onError: (err) => {
            setToasterWithError(err);
        },
        onCompleted: () => {
            onForgotPasswordComplete();
        },
    });

    const handleSubmit = ({ email }: any): void => {
        const variables = {
            email,
        };
        forgotPassword({ variables });
    };

    return <ForgotPasswordForm handleSubmit={handleSubmit} />;
};

export default ForgotPasswordFormContainer;
