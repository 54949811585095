import { RouteComponentProps } from 'react-router-dom';
import React, { useEffect } from 'react';
import ReactGA, { FieldsObject } from 'react-ga';

ReactGA.initialize(`${process.env.REACT_APP_GA_TRACKING_ID}`);

function getZipCode(address: string) {
    const regexMatch = address.match(/\d{5},/g);

    return regexMatch?.pop()?.replace(',', '');
}

const withRouter = (WrappedComponent: React.FC<RouteComponentProps>, options: FieldsObject = {}) => {
    const trackPage = (page: string) => {
        ReactGA.set({ page, ...options });
        ReactGA.pageview(page);
    };

    // eslint-disable-next-line react/display-name
    return (props: RouteComponentProps) => {
        useEffect(() => {
            trackPage(`${props.location.pathname}${getZipCode(decodeURIComponent(props.location.search))}`);
        }, [props.location.pathname, props.location.search]);

        return <WrappedComponent {...props} />;
    };
};

export default withRouter;
