import { omit } from 'lodash';
import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

interface NamedRouteProps extends RouteProps {
    name?: string;
}

const NamedRoute: React.FC<NamedRouteProps> = (props) => {
    return <Route {...omit(props, 'name')} />;
};

export default NamedRoute;
