// TODO: Once we solve the placement of the autocomplete results,
// this utility can be removed from PageLayoutComponent.
const blurOnScroll = (): void => {
    const elements = document.getElementsByClassName('pac-target-input');

    for (let i = 0; i < elements.length; i++) {
        const pacTargetInput = elements[i];
        (pacTargetInput as HTMLInputElement).blur();
    }
};

export default blurOnScroll;
