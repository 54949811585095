import { DeleteProfileModal } from '../DeleteProfile';
import { EmailVerificationModal } from '../EmailVerification';
import { ForgotPasswordModal } from '../ForgotPassword';
import { ResetPasswordModal } from '../ResetPassword';
import { SignInFormModal } from '../SignInForm';
import { SignUpFormModal } from '../SignUpForm';
import { useDialog } from './useDialog';
import React from 'react';

type ModalsProvider = {
    children: React.ReactNode;
};

const DeleteProfileModalContext = React.createContext<React.Dispatch<React.SetStateAction<boolean>> | undefined>(undefined);
const EmailVerificationModalContext = React.createContext<React.Dispatch<React.SetStateAction<boolean>> | undefined>(undefined);
const ForgotPasswordModalContext = React.createContext<React.Dispatch<React.SetStateAction<boolean>> | undefined>(undefined);
const ResetPasswordModalContext = React.createContext<React.Dispatch<React.SetStateAction<boolean>> | undefined>(undefined);
const SignInModalContext = React.createContext<React.Dispatch<React.SetStateAction<boolean>> | undefined>(undefined);
const SignUpModalContext = React.createContext<React.Dispatch<React.SetStateAction<boolean>> | undefined>(undefined);

const ModalsProvider = ({ children }: ModalsProvider) => {
    const deleteProfileModal = useDialog();
    const emailVerificationModal = useDialog();
    const forgotPasswordModal = useDialog();
    const resetPasswordModal = useDialog();
    const signInModal = useDialog();
    const signUpModal = useDialog();

    return (
        <DeleteProfileModalContext.Provider value={deleteProfileModal.setIsOpen}>
            <EmailVerificationModalContext.Provider value={emailVerificationModal.setIsOpen}>
                <ForgotPasswordModalContext.Provider value={forgotPasswordModal.setIsOpen}>
                    <SignInModalContext.Provider value={signInModal.setIsOpen}>
                        <SignUpModalContext.Provider value={signUpModal.setIsOpen}>
                            <ResetPasswordModalContext.Provider value={resetPasswordModal.setIsOpen}>
                                <DeleteProfileModal {...deleteProfileModal} />
                                <EmailVerificationModal {...emailVerificationModal} />
                                <ResetPasswordModal {...resetPasswordModal} />
                                <ForgotPasswordModal {...forgotPasswordModal} />
                                <SignInFormModal {...signInModal} />
                                <SignUpFormModal {...signUpModal} />
                                {children}
                            </ResetPasswordModalContext.Provider>
                        </SignUpModalContext.Provider>
                    </SignInModalContext.Provider>
                </ForgotPasswordModalContext.Provider>
            </EmailVerificationModalContext.Provider>
        </DeleteProfileModalContext.Provider>
    );
};

export const useDeleteProfileModal = () => {
    const context = React.useContext(DeleteProfileModalContext);
    if (context === undefined) {
        throw new Error('useDeleteProfileModal must be used within a DeleteProfileModalProvider');
    }

    return context;
};

export const useEmailVerificationModal = () => {
    const context = React.useContext(EmailVerificationModalContext);
    if (context === undefined) {
        throw new Error('useEmailVerificationModal must be used within a EmailVerificationModalProvider');
    }

    return context;
};

export const useForgotPasswordModal = () => {
    const context = React.useContext(ForgotPasswordModalContext);
    if (context === undefined) {
        throw new Error('useForgotPasswordModal must be used within a ForgotPasswordModalProvider');
    }

    return context;
};

export const useResetPasswordModal = () => {
    const context = React.useContext(ResetPasswordModalContext);
    if (context === undefined) {
        throw new Error('useResetPasswordModal must be used within a ResetPasswordModalProvider');
    }

    return context;
};

export const useSignInModal = () => {
    const context = React.useContext(SignInModalContext);
    if (context === undefined) {
        throw new Error('useSignInModal must be used within a SignInModalProvider');
    }

    return context;
};

export const useSignUpModal = () => {
    const context = React.useContext(SignUpModalContext);
    if (context === undefined) {
        throw new Error('useSignUpModal must be used within a SignUpModalProvider');
    }

    return context;
};

export default ModalsProvider;
