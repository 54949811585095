import ImageSuggestionFormContainer from '../ImageSuggestionFormContainer';
import Modal, { HeightType } from '../../Modal';
import React, { Dispatch, SetStateAction } from 'react';

type Props = {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    rep: any;
};

const ImageSuggestionModal: React.FC<Props> = ({ isOpen, setIsOpen, rep }) => {
    return (
        <Modal title='Submit an image' height={HeightType.Short} isOpen={isOpen} setIsOpen={setIsOpen}>
            <ImageSuggestionFormContainer onDismiss={() => setIsOpen(false)} rep={rep} />
        </Modal>
    );
};

export default ImageSuggestionModal;
