import { closeOutline } from 'ionicons/icons';
import { getClass } from '../../../utils';
import { IonCardContent, IonContent, IonIcon, IonModal } from '@ionic/react';
import { ModalOptions } from '@ionic/core';
import { UseDialogProps } from '../../ModalsProvider';
import cn from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

export enum HeightType {
    Short = 'short',
    Medium = 'medium',
    Tall = 'tall',
}

type Props = Omit<ModalOptions, 'component'> & {
    children: React.ReactNode;
    title: string;
    subTitle?: string;
    height?: HeightType;
} & Partial<UseDialogProps>;

const Modal: React.FC<Props> = ({
    animated = true,
    children,
    getDialogDescriptionProps,
    getDialogLabelProps,
    height = HeightType.Medium,
    isOpen,
    setIsOpen,
    subTitle,
    title,
}) => {
    return (
        <IonModal animated={animated} isOpen={isOpen ?? false} backdropDismiss={false} className={cn(styles.modalWrapper, styles[height])}>
            <IonContent onClick={(e) => e.stopPropagation()} className={styles.container}>
                <span
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsOpen?.(false);
                    }}
                    className={getClass(styles, 'closeBtn')}
                >
                    <IonIcon icon={closeOutline}></IonIcon>
                </span>
                <h2 {...(getDialogLabelProps ? getDialogLabelProps() : {})} className={getClass(styles, 'modalTitle')}>
                    {title}
                </h2>
                {subTitle && (
                    <p {...(getDialogDescriptionProps ? getDialogDescriptionProps() : {})} className={styles.subTitle}>
                        {subTitle}
                    </p>
                )}
                <IonCardContent className={styles.cardWrapper}>{children}</IonCardContent>
            </IonContent>
        </IonModal>
    );
};

export default Modal;
