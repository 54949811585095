import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { getClass } from '../../../utils';
import React from 'react';
import styles from './styles.module.scss';

type Props = {
    items: ItemProp[];
};

type ItemProp = {
    heading: string;
    panel: string | React.ReactNode;
};

const AccordionComponent: React.FC<Props> = ({ items }) => {
    const accordionItems = items.map((item: ItemProp, i: number) => (
        <AccordionItem uuid={`${i}`} className={styles.accordionItem} key={item.heading + i}>
            <AccordionItemHeading className={styles.accordionItemHeading}>
                <AccordionItemButton className={getClass(styles, 'accordionItemButton')}>{item.heading}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={getClass(styles, 'accordionPanel')}>{item.panel}</AccordionItemPanel>
        </AccordionItem>
    ));

    return (
        <Accordion allowMultipleExpanded allowZeroExpanded preExpanded={['0']} className={styles.accordion}>
            {accordionItems}
        </Accordion>
    );
};

export default AccordionComponent;
