import { chain } from 'lodash';
import { ElectedOfficial, JurisdictionEnum, JurisdictionLabel, useFetchOfficialsByAddressLazyQuery } from '../../@types/generated-gql-typed-hooks';
import { getSearchParams, resolveUserAddress } from '../../utils';
import { JurisdictionItem } from './types';
import { RouteComponentProps } from 'react-router';
import { useAuthenticatedUser } from '../AuthenticatedUser';
import { useIonViewDidEnter } from '@ionic/react';
import _get from 'lodash/get';
import MyRepsComponent from './MyRepsComponent';
import React, { useCallback, useEffect, useState } from 'react';
import withTracker from '../withTracker';
import { getStateName } from '../../utils/stateMapper';

const MyRepsContainer: React.FC<RouteComponentProps<any>> = ({ history, location }) => {
    const [{ address: userAddressFromContext = '', curiosityAddress = '' }, setUserContext] = useAuthenticatedUser();
    const [userAddressState, setUserAddressState] = useState<string>('');
    const [officialsList, setOfficialsList] = useState<Array<JurisdictionItem>>([]);
    const [jurisdictionLabels, setJurisdictionLabels] = useState<Array<JurisdictionLabel>>([]);
    const [stateName, setStateName] = useState<string>(''); // State to hold the user's state
    const [refetch, { data: queryData, loading }] = useFetchOfficialsByAddressLazyQuery({
        variables: { address: userAddressState },
    });

    const getAddressFromSearchParams = useCallback(() => {
        const { searchParams } = getSearchParams(location);
        const searchParamsAddress = _get(searchParams, 'address', '') as string;
        return searchParamsAddress;
    }, [location]);

    useEffect(() => {
        // Set state with address from search params
        const searchParamsAddress = getAddressFromSearchParams();
        setUserAddressState(searchParamsAddress);
    }, [location, getAddressFromSearchParams]);

    useEffect(() => {
        // Fetch from backend upon change of address in state
        if (userAddressState) {
            refetch();
        }
    }, [userAddressState, refetch]);

    useIonViewDidEnter(() => {
        // On Page render, set url search params with address from user context, only if it doesn't already exist in search params
        const searchParamsAddress = getAddressFromSearchParams();
        resolveUserAddress(history, searchParamsAddress, curiosityAddress, userAddressFromContext, setUserContext);
    }, [userAddressFromContext, curiosityAddress, getAddressFromSearchParams]);

    useEffect(() => {
        if (queryData) {
            const repsData: ElectedOfficial[] = (
                _get(queryData, 'fetchOfficialsByAddress.officials', []) as (ElectedOfficial | null | undefined)[]
            ).filter((official): official is ElectedOfficial => official !== null && official !== undefined);

            // Extract jurisdiction labels from the queryData
            const labels = _get(queryData, 'fetchOfficialsByAddress.jurisdictionLabels', []) as JurisdictionLabel[];

            // Extract the state from the query response
            const stateAbbreviation = _get(queryData, 'fetchOfficialsByAddress.address.state', '');
            const state = getStateName(stateAbbreviation);
            setStateName(state || '');

            // Format representatives data without adding the labels
            const formattedReps: JurisdictionItem[] = chain(repsData)
                .filter(({ jurisdiction }) => !!jurisdiction)
                .groupBy('jurisdiction')
                .mapValues((val: ElectedOfficial[], key: JurisdictionEnum) => ({
                    jurisdiction: key,
                    repsList: val,
                }))
                .map((val) => val as unknown as JurisdictionItem)
                .toArray()
                .reverse()
                .value();

            setOfficialsList(formattedReps);
            setJurisdictionLabels(labels);
        }
    }, [queryData]);

    const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setUserAddressState(e.target.value);
    };

    const handlePlaceSelected = (address: string): void => {
        setUserContext((prevState) => ({ ...prevState, curiosityAddress: address }));
        history.push(`/my-reps?address=${address}`);
    };

    return (
        <MyRepsComponent
            onLoading={!!userAddressState && loading}
            repsData={officialsList}
            onAddressChange={handleAddressChange}
            userAddress={userAddressState}
            handlePlaceSelected={handlePlaceSelected}
            jurisdictionLabels={jurisdictionLabels} // Pass jurisdiction labels separately here
            stateName={stateName} // Pass the state name to the component
        />
    );
};

export default withTracker(MyRepsContainer);
