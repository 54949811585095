import { IonCol } from '@ionic/react';
import React from 'react';
import styles from './styles.module.scss';

type Props = {
    title: string;
    values: { value: string; href?: string }[];
};

const Modal: React.FC<Props> = ({ title, values }) => {
    return (
        <IonCol size='6'>
            <div className={styles.contact}>
                <span className={styles.contactTitle}>{title}</span>
                {values?.map(({ value, href }: { value: string; href?: string }, index: number) => (
                    <span key={index} className={styles.contactSubtitle}>
                        {href ? (
                            <a rel='external' target='blank' href={href} className='clickLink'>
                                {value}
                            </a>
                        ) : (
                            value
                        )}
                    </span>
                ))}
            </div>
        </IonCol>
    );
};

export default Modal;
