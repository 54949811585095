import { useAuthenticatedUser } from '../AuthenticatedUser';
import { useEmailVerificationModal } from '../ModalsProvider';
import { useRegisterMutation } from '../../@types/generated-gql-typed-hooks';
import { useToasterWithError } from '../WithToaster';
import merge from 'lodash/merge';
import React from 'react';
import SignUpForm from './SignUpForm';

type Props = {
    onSignUpComplete: () => void;
};

const SignUpFormContainer: React.FC<Props> = ({ onSignUpComplete }) => {
    const setToasterWithError = useToasterWithError();
    const setEmailVerificationModalState = useEmailVerificationModal();
    const [, updateUserContext] = useAuthenticatedUser();

    const [registerUser] = useRegisterMutation({
        onError: (err) => setToasterWithError(err),
        onCompleted: ({ register }) => {
            const user = register.user;

            updateUserContext((prevState) => ({
                ...merge(prevState, user),
            }));
            onSignUpComplete();
            setEmailVerificationModalState(true);
        },
    });

    const handleSubmit = ({ email, password, first_name, last_name, address }: any): void => {
        const variables = {
            username: email,
            email,
            password,
            first_name,
            last_name,
            address,
        };

        registerUser({ variables });
    };

    return <SignUpForm handleSubmit={handleSubmit} />;
};

export default SignUpFormContainer;
