import { useResetPasswordMutation } from '../../@types/generated-gql-typed-hooks';
import { useToasterWithError } from '../WithToaster';
import React from 'react';
import SetPasswordForm from '../shared/SetPasswordForm';

type ResetPasswordContainerProps = {
    token: string;
    onComplete: () => void;
};

const ResetPasswordContainer: React.FC<ResetPasswordContainerProps> = ({ token, onComplete }) => {
    const setToasterWithError = useToasterWithError();

    const [resetPassword] = useResetPasswordMutation({
        onCompleted: onComplete,
        onError: (err) => setToasterWithError(err),
    });

    const handleSubmit = ({ newPassword, confirmPassword }: any): void => {
        resetPassword({
            variables: { password: newPassword, passwordConfirmation: confirmPassword, code: token },
        });
    };

    return <SetPasswordForm reset={true} submitBtnText='Reset' handleSubmit={handleSubmit} />;
};

export default ResetPasswordContainer;
