import * as serviceWorker from './serviceWorker';
import { createRoot } from 'react-dom/client';
import App from './App';
import React from 'react';

const root = createRoot(document.getElementById('root') as Element);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
