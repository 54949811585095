import { closeOutline, logoFacebook, logoTwitter, logoYoutube } from 'ionicons/icons';
import { ComponentContactsContacts, ElectedOfficial } from '../../../@types/generated-gql-typed-hooks';
import { IonContent, IonGrid, IonIcon, IonModal, IonRouterLink, IonRow } from '@ionic/react';
import { ModalOptions } from '@ionic/core';
import { selectRepPhoto } from '../../../utils';
import { UseDialogProps } from '../../ModalsProvider';
import _isEmpty from 'lodash/isEmpty';
import cn from 'classnames';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import React, { useEffect, useState } from 'react';
import RepInfoContactColumn from './RepInfoContactColumn';
import RepThumbnailImage from '../../RepThumbnailImage';
import styles from './styles.module.scss';
import { TwitterTimelineEmbed } from 'react-twitter-embed'; // Import the Twitter embed component

type Props = Omit<ModalOptions, 'component'> & {
    title?: string;
    subTitle?: string;
    rep?: ElectedOfficial;
} & Partial<UseDialogProps>;

type GroupedContacts = {
    [key: string]: Array<Pick<ComponentContactsContacts, 'type' | 'value'> | null>;
};

const socialIconLookup = {
    youtube: logoYoutube,
    facebook: logoFacebook,
    twitter: logoTwitter,
};

const socialLinkLookup = {
    youtube: 'https://www.youtube.com/user/',
    facebook: 'https://www.facebook.com/',
    twitter: 'https://twitter.com/',
    GooglePlus: 'https://plus.google.com/',
};

const Modal: React.FC<Props> = ({ animated = true, isOpen, setIsOpen, rep }) => {
    const [contacts, setContacts] = useState<GroupedContacts>();

    useEffect(() => {
        setContacts(groupBy(rep?.contacts, 'type'));
    }, [rep]);

    // Find the Twitter handle from the social links
    const twitterHandle = rep?.social?.find((s) => s?.type === 'twitter')?.value;

    return (
        <IonModal animated={animated} isOpen={isOpen ?? false} backdropDismiss={false} className={styles.modalWrapper}>
            <IonContent className={styles.container}>
                <div className={cn(styles.item, styles.container)}>
                    <span onClick={() => setIsOpen?.(false)} className={styles.closeBtn}>
                        <IonIcon icon={closeOutline}></IonIcon>
                    </span>
                    <RepThumbnailImage imageSrc={selectRepPhoto({ photoUrl: rep?.photoUrl, picture: rep?.picture })} rep={rep} />
                    <div className={styles.text}>
                        <h3 className={styles.name}>{rep?.name}</h3>
                        <span className={styles.title}>{rep?.office}</span>
                        <span className={styles.title}>{rep?.party}</span>
                        <div className={styles.logoContainer}>
                            {rep?.social?.map((componentSocialSocial, index: number) => {
                                const type = get(componentSocialSocial, 'type') as string;
                                const value = get(componentSocialSocial, 'value') as string;
                                const href = `${get(socialLinkLookup, type)}${value}`;

                                return (
                                    <IonRouterLink key={index} color='none' href={href} rel='external' target='blank' className={styles.routerLink}>
                                        <IonIcon className={styles.logo} icon={get(socialIconLookup, type)} />
                                    </IonRouterLink>
                                );
                            })}
                        </div>
                    </div>
                </div>

                <IonGrid className={styles.contacts}>
                    <IonRow>
                        {rep?.website && <RepInfoContactColumn title='Website' values={[{ value: rep.website, href: rep.website }]} />}
                        {contacts?.email && (
                            <RepInfoContactColumn
                                title='Email'
                                values={contacts.email.map(({ value }: any) => ({ value, href: `mailto:${value}` }))}
                            />
                        )}
                        {!_isEmpty(contacts?.address) && <RepInfoContactColumn title='Address' values={contacts?.address as []} />}
                        {!_isEmpty(contacts?.voice) && <RepInfoContactColumn title='Phone' values={contacts?.voice as []} />}
                    </IonRow>
                </IonGrid>

                {/* Twitter Embed at the bottom */}
                {twitterHandle && (
                    <div className={styles.twitterContainer}>
                        <TwitterTimelineEmbed sourceType='profile' screenName={twitterHandle} options={{ height: 400 }} />
                    </div>
                )}
            </IonContent>
        </IonModal>
    );
};

export default Modal;
