import { useSignInModal, useSignUpModal } from '../../ModalsProvider';
import Button, { ColorTypes } from '../Button';
import Modal, { HeightType } from '../Modal';
import React, { Dispatch, SetStateAction } from 'react';
import styles from './styles.module.scss';

type Props = {
    title?: string;
    subTitle?: string;
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const SignInSignUpModal: React.FC<Props> = ({ isOpen, setIsOpen, title = 'Oops. Do you have an account?', subTitle }) => {
    const setSignInModalState = useSignInModal();
    const setSignUpModalState = useSignUpModal();

    return (
        <Modal setIsOpen={setIsOpen} isOpen={isOpen} title={title} subTitle={subTitle} height={HeightType.Short}>
            <div className={styles.buttonContainer}>
                <Button
                    className={styles.button}
                    colorType={ColorTypes.Primary}
                    onClick={() => {
                        setIsOpen(false);
                        setSignUpModalState(true);
                    }}
                >
                    Sign Up
                </Button>
                <span
                    className='clickLink'
                    onClick={() => {
                        setIsOpen(false);
                        setSignInModalState(true);
                    }}
                >
                    Sign In
                </span>
            </div>
        </Modal>
    );
};

export default SignInSignUpModal;
