import { UseDialogProps } from '../../ModalsProvider';
import DeleteProfileFormContainer from '../DeleteProfileFormContainer';
import Modal, { HeightType } from '../../shared/Modal';
import React from 'react';

type Props = UseDialogProps;

const DeleteProfileModalComponent: React.FC<Props> = ({ setIsOpen, ...props }) => {
    return (
        <Modal
            title='Delete Profile'
            subTitle='Are you sure you want to delete your profile?  This action cannot be undone.'
            setIsOpen={setIsOpen}
            {...props}
            height={HeightType.Short}
        >
            <DeleteProfileFormContainer onDeleteProfileComplete={() => setIsOpen(false)} />
        </Modal>
    );
};

export default DeleteProfileModalComponent;
