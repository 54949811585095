import React, { createContext, useContext, ReactNode } from 'react';
import { useIonToast, ToastOptions } from '@ionic/react';
import styles from './styles.module.scss';
interface ToastContextType {
  presentSuccessToast: (message: string, options?: Partial<ToastOptions>) => void;
  presentErrorToast: (message: string, options?: Partial<ToastOptions>) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const withToasterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [present] = useIonToast();

  const defaultOptions: Partial<ToastOptions> = {
    duration: 3000,
    position: 'top',
    cssClass: styles.toast,
  };

  const presentSuccessToast = (message: string, options?: Partial<ToastOptions>) => {
    present({
      ...defaultOptions,
      ...options,
      message,
      color: 'success',
    });
  };

  const presentErrorToast = (message: string, options?: Partial<ToastOptions>) => {
    present({
      ...defaultOptions,
      ...options,
      message,
      color: 'danger',
    });
  };

  return (
    <ToastContext.Provider value={{ presentSuccessToast, presentErrorToast }}>
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

export default withToasterProvider;