import { UseDialogProps } from '../../ModalsProvider';
import Modal, { HeightType } from '../../shared/Modal';
import React from 'react';
import SignUpForm from '../';

type Props = UseDialogProps;

const SignUpFormModal: React.FC<Props> = ({ setIsOpen, ...props }) => (
    <Modal
        title='Sign up'
        subTitle='Create an account to find your reps and learn their voting records.'
        setIsOpen={setIsOpen}
        {...props}
        height={HeightType.Tall}
    >
        <SignUpForm onSignUpComplete={() => setIsOpen(false)} />
    </Modal>
);
export default SignUpFormModal;
