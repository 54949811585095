import { ConnectedInput, InputModeTypes, InputTypes } from '../Input';
import { ConnectedInputProps } from '../Input/types';
import React, { FC } from 'react';

type Props = Pick<ConnectedInputProps, 'name' | 'label' | 'defaultValue' | 'registerOptions' | 'disabled'> & { masked?: boolean };

const PasswordInput: FC<Props> = ({ name, label, defaultValue, registerOptions: moreOptions, disabled }) => {
    //regex password for at least 8 characters, a number and a special character
    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/;

    return (
        <ConnectedInput
            name={name}
            label={label}
            type={InputTypes.Password}
            registerOptions={{
                required: true,
                pattern: {
                    value: regex,
                    message: 'Password should contain at least 6 characters, a number and a special character',
                },
                ...moreOptions,
            }}
            inputMode={InputModeTypes.Text}
            disabled={disabled}
            defaultValue={defaultValue}
        />
    );
};

export default PasswordInput;
