import { History } from 'history';
import _isEmpty from 'lodash/isEmpty';
export default (
    history: History,
    searchParamsAddress: string | null | undefined,
    curiosityAddress: string | null | undefined,
    userAddressFromContext: string | null | undefined,
    setUserContext: any
) => {
    // On Page render, set url search params with address from user context, only if it doesn't already exist in search params
    if (!searchParamsAddress) {
        const address = curiosityAddress || userAddressFromContext;

        !_isEmpty(address) && history.push(`/my-reps?address=${String(address)}`);
    } else {
        setUserContext((prevState: any) => ({ ...prevState, curiosityAddress: searchParamsAddress }));
    }
};
