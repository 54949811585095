import { useAuthenticatedUser } from '../../AuthenticatedUser';
import { useSubmitImageSuggestionMutation } from '../../../@types/generated-gql-typed-hooks';
import { useToasterWithError } from '../../WithToaster';
import ImageSuggestionForm from './ImageSuggestionForm';
import React from 'react';

type ImageSuggestionFormContainerProps = {
    onDismiss: () => void;
    rep: any;
};

const ImageSuggestionFormContainer: React.FC<ImageSuggestionFormContainerProps> = ({ onDismiss, rep }) => {
    const setToasterWithError = useToasterWithError();
    const [userContext] = useAuthenticatedUser();
    const { name, google_id } = rep;
    const { id } = userContext;

    const [submitImageSuggestionMutation] = useSubmitImageSuggestionMutation({
        onError: (error) => setToasterWithError(error),
        onCompleted: () => {
            onDismiss();
        },
    });

    const onSubmit = ({ image_link, image_source }: any): void => {
        const variables = {
            image_source: image_source,
            image_link: image_link,
            name: name,
            google_id: google_id,
            user: id || '',
        };
        submitImageSuggestionMutation({ variables });
    };

    return <ImageSuggestionForm onSubmit={onSubmit} />;
};

export default ImageSuggestionFormContainer;
