import Accordion from '../shared/Accordion';
import React from 'react';
import RepDetailsHeader from '../RepDetailsHeader';
import SplitPageLayout from '../SplitPageLayout';

type RepProfilePageComponentProps = {
    repDetails: {
        imgSrc: string;
        name: string;
        title: string;
        party: string;
        address: string;
    };
    contentTitle: string;
    isLoading?: boolean;
};

const RepProfilePageComponent: React.FC<RepProfilePageComponentProps> = ({ repDetails, contentTitle, isLoading = false }) => (
    <SplitPageLayout
        leftPane={<RepDetailsHeader {...repDetails} />}
        rightPane={<Accordion items={[]} />}
        contentTitle={contentTitle}
        isLoading={isLoading}
    />
);

export default RepProfilePageComponent;
