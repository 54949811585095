import { UseDialogProps } from '../../ModalsProvider';
import ForgotPasswordFormContainer from '../ForgotPasswordFormContainer';
import Modal, { HeightType } from '../../shared/Modal';
import React from 'react';
import { useToast } from '../../WithToaster/WithToasterComponent';

type Props = UseDialogProps;

const ForgotPasswordModalComponent: React.FC<Props> = ({ setIsOpen, ...props }) => {
    const { presentSuccessToast } = useToast();
    const onForgotPasswordComplete = () => {
        setIsOpen(false);
        presentSuccessToast('An email has been sent to your inbox with a link to reset your password');
    };
    return (
        <Modal
            title='Reset your password'
            subTitle="Enter your email and we'll send you a link to get back into your account."
            setIsOpen={setIsOpen}
            {...props}
            height={HeightType.Short}
        >
            <ForgotPasswordFormContainer onForgotPasswordComplete={onForgotPasswordComplete} />
        </Modal>
    );
};

export default ForgotPasswordModalComponent;
