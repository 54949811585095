import { getSearchParams } from '../../../utils';
import { UseDialogProps, useSignInModal } from '../../ModalsProvider';
import { useLocation } from 'react-router-dom';
import Modal, { HeightType } from '../../shared/Modal';
import React, { useEffect, useState } from 'react';
import ResetPasswordContainer from '../ResetPasswordContainer';
import { useHistory } from 'react-router-dom';
import { delay } from 'lodash';
import { useToast } from '../../WithToaster/WithToasterComponent';

type Props = UseDialogProps;

const ResetPasswordModalContainer: React.FC<Props> = ({ setIsOpen, isOpen, ...props }) => {
    const location = useLocation();
    const history = useHistory();
    const [tokenString, setTokenState] = useState<string>('');
    const setSignInModalState = useSignInModal();
    const { presentSuccessToast } = useToast();

    useEffect(() => {
        const { searchParams } = getSearchParams(location);
        const token = searchParams['reset-password-token'];

        if (!tokenString && typeof token === 'string') {
            setTokenState(token);
            setIsOpen(true);
            history.replace('/');
        }
    }, [location, tokenString, setIsOpen, history]);

    const onComplete = () => {
        setIsOpen(false);
        setTokenState('');
        presentSuccessToast('Password has been reset. Please sign in.');
        delay(() => {
            setSignInModalState(true);
        }, 300);
    };

    return (
        <Modal height={HeightType.Short} title='Reset your password' isOpen={isOpen && !!tokenString} setIsOpen={setIsOpen} {...props}>
            <ResetPasswordContainer onComplete={onComplete} token={tokenString} />
        </Modal>
    );
};

export default ResetPasswordModalContainer;
