import { IonButton } from '@ionic/react';
import { JSX } from '@ionic/core';
import cn from 'classnames';
import React, { MouseEvent } from 'react';
import styles from './styles.module.scss';

export enum ColorTypes {
    Primary = 'primary',
    Secondary = 'secondary',
    Tertiary = 'tertiary',
}

export enum SlotTypes {
    Start = 'start',
    End = 'end',
    IconOnly = 'icon-only',
}

type IonicReactButtonProps = Pick<
    JSX.IonButton,
    'disabled' | 'strong' | 'size' | 'href' | 'rel' | 'target' | 'routerAnimation' | 'buttonType' | 'expand' | 'type'
>;

interface Props extends IonicReactButtonProps {
    icon?: string;
    slot?: SlotTypes;
    colorType: ColorTypes;
    className?: string;
    routerLink?: string | undefined;
    routerDirection?: 'none' | 'forward' | 'back' | 'root' | undefined;
    register?: any;
    onClick?: ((event: MouseEvent<HTMLIonButtonElement> | any) => void) | undefined;
    shouldStartCaseText?: boolean;
    children?: React.ReactNode;
}

const Button: React.FC<Props> = ({ className, type, href, children, colorType, onClick, size = 'default', shouldStartCaseText = false }) => {
    const classNamesObj = {
        [styles.btn]: true,
        [styles[colorType]]: !!colorType,
    };

    return (
        <IonButton href={href} type={type} color={colorType} size={size} shape='round' className={cn(classNamesObj, className)} onClick={onClick}>
            <span className={cn(styles.alignment, shouldStartCaseText && styles.capitalized)}>{children}</span>
        </IonButton>
    );
};

export default Button;
