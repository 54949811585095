import { InputModeTypes, InputProps, InputStyleTypes, InputTypes } from './types';
import { IonIcon, IonInput, IonInputPasswordToggle } from '@ionic/react';
import cn from 'classnames';
import ErrorOrDescription from './ErrorOrDescription';
import React from 'react';
import styles from './styles.module.scss';

import { closeOutline } from 'ionicons/icons';

const Input: React.FC<InputProps> = ({
    clearX,
    value,
    name,
    label,
    className,
    toggleEdit,
    onBlur,
    onFocus,
    errorMessage = 'Invalid Input',
    autocomplete = 'on',
    showError,
    description,
    styleType = InputStyleTypes.Outline,
    type = InputTypes.Text,
    inputMode = InputModeTypes.Text,
    handleChange,
    required,
    disabled,
    icon,
    ...rest
}) => {
    return (
        <div className={styles.inputContainer}>
            <span className={cn(styles.inputFieldWrapper, styles[styleType])}>
                <IonInput
                    name={name}
                    autocomplete={autocomplete}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    className={cn(styles.input, disabled && styles.disabled, icon && styles.withIcon, className)}
                    type={type}
                    value={value}
                    onIonInput={handleChange}
                    label={label}
                    required={required}
                    inputMode={inputMode}
                    disabled={disabled}
                    {...rest}
                >
                    {type === InputTypes.Password && <IonInputPasswordToggle slot='end'></IonInputPasswordToggle>}
                </IonInput>
                {clearX && <IonIcon onMouseDown={toggleEdit} className={styles.xIcon} icon={closeOutline} size='small' />}
            </span>
            <ErrorOrDescription description={description} showError={showError} errorMessage={errorMessage} />
        </div>
    );
};

export default Input;
