import './index.css';
import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { AuthenticatedUserProvider } from './components/AuthenticatedUser';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ModalsProvider } from './components/ModalsProvider';
import { onError } from '@apollo/client/link/error';
import { setupIonicReact } from '@ionic/react';
import React from 'react';
import toLower from 'lodash/toLower';
import WithToasterProvider from './components/WithToaster';
import AppRoutes from './AppRoutes';
import Navigation from './components/Navigation';

const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_GRAPHQL_API_URI}`,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
            if (message === 'Invalid token.') {
                localStorage.removeItem('token');
            }
            console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        });

    if (networkError) console.error(`[Network error]: ${networkError}`);
});

const excludeTokenOperations = ['login', 'register'];

const authMiddleware = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem('token');
    if (!excludeTokenOperations.includes(toLower(operation.operationName))) {
        operation.setContext({
            headers: { Authorization: token ? `Bearer ${token.slice(1, -1)}` : '' },
        });
    }

    return forward(operation);
});

const client = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies: {
            ElectedOfficial: {
                keyFields: ['google_id'],
            },
        },
    }),
    link: from([authMiddleware, errorLink, httpLink]),
});

setupIonicReact();

const App: React.FC = () => (
    <IonApp>
        <IonReactRouter>
            <ApolloProvider client={client}>
                <WithToasterProvider>
                    <AuthenticatedUserProvider>
                        <ModalsProvider>
                            <IonRouterOutlet id='content'>
                                <AppRoutes />
                            </IonRouterOutlet>
                            <Navigation />
                        </ModalsProvider>
                    </AuthenticatedUserProvider>
                </WithToasterProvider>
            </ApolloProvider>
        </IonReactRouter>
    </IonApp>
);

export default App;
