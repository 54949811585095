import {
    UpdateUserInfoMutation,
    UpdateUserPasswordMutation,
    useUpdateUserInfoMutation,
    useUpdateUserPasswordMutation,
} from '../../@types/generated-gql-typed-hooks';
import { useAuthenticatedUser } from '../AuthenticatedUser';
import { useToasterWithError } from '../WithToaster';
import AccountForm from './AccountForm';
import merge from 'lodash/merge';
import React from 'react';
import withTracker from '../withTracker';
import { useToast } from '../WithToaster/WithToasterComponent';

const AccountFormContainer: React.FC = () => {
    const [, updateUserContext] = useAuthenticatedUser();
    const setToasterWithError = useToasterWithError();
    const { presentSuccessToast } = useToast();

    const mergeUserData = (userData: UpdateUserInfoMutation['updateMe'] | UpdateUserPasswordMutation['updateMe']) => {
        const user = userData;

        updateUserContext(
            /* istanbul ignore next */ (prevState) => ({
                ...merge(prevState, user),
            })
        );
    };

    const [updateUserAccount] = useUpdateUserInfoMutation({
        onError: (err) => setToasterWithError(err),
        onCompleted: ({ updateMe }) => {
            mergeUserData(updateMe);
            presentSuccessToast('Your personal info has been updated');
        },
    });

    const [updateUserPassword] = useUpdateUserPasswordMutation({
        onError: /* istanbul ignore next */ (err) => setToasterWithError(err),
        onCompleted: /* istanbul ignore next */ ({ updateMe }) => {
            mergeUserData(updateMe);
            presentSuccessToast('Your password has been updated');
        },
    });

    const onUpdateInfo = ({ email, first_name, last_name, address }: any): void => {
        const variables = { address, email, first_name, last_name };
        updateUserAccount({ variables });
    };

    const onUpdatePassword = async ({ newPassword, oldPassword }: any) => {
        const variables = { password: newPassword, oldPassword: oldPassword };
        updateUserPassword({ variables });
    };

    return <AccountForm onUpdateInfo={onUpdateInfo} onUpdatePassword={onUpdatePassword} />;
};

export default withTracker(AccountFormContainer);
