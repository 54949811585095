import { getPlatforms } from '@ionic/core';
import cn from 'classnames';

/*
    setup: for any class that needs a mobile specific view (ex. styles applied only to max-width: $mobile-breakpoint)
    add a &-mobile class with the same styles.

    usage: pass in your scss ctyles and the key for your classname
    it will automatically apply any mobile specific classes with the &-mobile tag
*/
export function getClass(styles: { readonly [key: string]: string }, className: string) {
    const currentViewType = getDeviceViewType();

    return cn(styles[className], ...currentViewType.map(viewType => styles[`${className}-${viewType}`]));
}

export function getDeviceViewType() {
    return getPlatforms();
}

export default { getClass, getDeviceViewType };
