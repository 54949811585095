import { getSearchParams } from '../../../utils';
import { UseDialogProps } from '../../ModalsProvider';
import { useLocation } from 'react-router-dom';
import Modal, { HeightType } from '../../shared/Modal';
import React, { useEffect } from 'react';
import SignInFormContainer from '../SignInFormContainer';

type Props = UseDialogProps;

const SignInModal: React.FC<Props> = ({ setIsOpen, ...props }) => {
    const location = useLocation();

    useEffect(() => {
        const { searchParams } = getSearchParams(location);
        const isConfirmed = searchParams.confirmeduser as string;

        isConfirmed && setIsOpen(isConfirmed.toLowerCase() === 'true');
    }, [location, setIsOpen]);

    return (
        <Modal height={HeightType.Short} title='Sign in' setIsOpen={setIsOpen} {...props}>
            <SignInFormContainer />
        </Modal>
    );
};

export default SignInModal;
