import React from 'react';
// import { IonIcon, IonRouterLink, IonThumbnail } from '@ionic/react';
// import { chevronBackOutline } from 'ionicons/icons';
import RepThumbnailImage from '../RepThumbnailImage';
import styles from './styles.module.scss';

type RepDetailsHeaderComponentProps = {
    imgSrc: string;
    name: string;
    title: string;
    party: string;
    address: string;
};

const RepDetailsHeaderComponent: React.FC<RepDetailsHeaderComponentProps> = ({ imgSrc, name, title, party, address }) => {
    return (
        <div className={styles.container}>
            {/* TODO: Toggle hamburger and back button in Main nav instead */}
            {/* <IonRouterLink routerDirection='back' routerLink='/my-reps' className={styles.chevronIcon}>
                <IonIcon icon={chevronBackOutline} />
            </IonRouterLink> */}
            <RepThumbnailImage imageSrc={imgSrc} rep={{ name, title, party, address }} />
            <div className={styles.text}>
                <h1 className={styles.name}>{name}</h1>
                <span className={styles.title}>{title}</span>
                <span className={styles.party}>{party}</span>
                <span className={styles.address}>{address}</span>
            </div>
        </div>
    );
};

export default RepDetailsHeaderComponent;
