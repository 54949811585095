import { useId } from '@reach/auto-id';
import cn from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

type PropGetter<T> = (props: React.HTMLAttributes<T>) => React.HTMLAttributes<T>;

type DialogProps = {
    /**
     * Initial open state of the dialog. This is helpful for
     * routes begin with an open dialog.
     */
    isInitiallyOpen?: boolean;
};

export const useDialog = ({ isInitiallyOpen = false }: DialogProps = {}) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(isInitiallyOpen);
    const dialogLabelId = useId();
    const dialogDescriptionId = useId();

    /**
     * Set focus on the first focusable element when the dialog opens.
     */
    const firstFocusableElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
        if (firstFocusableElementRef?.current && isOpen) {
            firstFocusableElementRef.current.focus();
        }
    }, [firstFocusableElementRef, isOpen]);

    const getToggleProps: PropGetter<HTMLElement> = (props) => ({
        onClick: () => setIsOpen((isOpenState) => !isOpenState),
        ...props,
    });

    const getDialogProps = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement> = {}) => ({
        className: cn({ [styles.visuallyHidden]: !isOpen }, className),
        role: 'dialog',
        'aria-modal': true,
        'aria-labelledby': dialogLabelId,
        'aria-describedby': dialogDescriptionId,
        ...props,
    });

    const getScrimProps = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement> = {}) => ({
        className: cn({ [styles.visuallyHidden]: !isOpen }, className),
        onClick: () => setIsOpen(true),
        ...props,
    });

    const getDialogLabelProps = (props: React.HTMLAttributes<HTMLHeadingElement> = {}) => ({
        id: dialogLabelId,
        ...props,
    });

    const getDialogDescriptionProps = (props: React.HTMLAttributes<HTMLElement> = {}) => ({
        id: dialogDescriptionId,
        ...props,
    });

    const getFirstFocusableElementProps = (props: React.HTMLAttributes<HTMLElement> = {}) => ({
        ref: firstFocusableElementRef,
        ...props,
    });

    return {
        getDialogDescriptionProps,
        getDialogLabelProps,
        getDialogProps,
        getFirstFocusableElementProps,
        getScrimProps,
        getToggleProps,
        isOpen,
        setIsOpen,
    };
};

export type UseDialogProps = ReturnType<typeof useDialog>;

export type DialogRenderProps = DialogProps & {
    children: (props: UseDialogProps) => React.ReactNode;
};

export const Dialog = ({ children, ...dialogProps }: DialogRenderProps) => {
    return <>{children(useDialog(dialogProps))}</>;
};

export default useDialog;
