import { get as _get, merge as _merge } from 'lodash';
import { AuthenticatedUser } from './typeDefs';
import { AuthenticatedUserContext, DispatchAuthenticatedUserContext } from './authContext';
import { useMeLazyQuery } from '../../@types/generated-gql-typed-hooks';
import React, { FC, useEffect, useState } from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';
import { useToast } from '../WithToaster/WithToasterComponent';

type AuthenticatedUserProviderProps = {
    children: React.ReactNode;
};

export const authedUserFieldsObj = {
    id: '',
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    confirmed: false,
    address: '',
    provider: '',
    curiosityAddress: '',
    isAuthenticated: false,
    loading: false,
};

const AuthenticatedUserProvider: FC<AuthenticatedUserProviderProps> = ({ children }) => {
    const [authenticatedUser, setAuthenticatedUser] = useState<AuthenticatedUser>(authedUserFieldsObj);

    const [token, , removeToken] = useLocalStorage('token');

    const [getUser, { called, data, loading }] = useMeLazyQuery();

    const { presentSuccessToast } = useToast();

    useEffect(() => {
        if (token) {
            getUser();
        }
    }, [getUser, token]);

    useEffect(() => {
        if (called && loading) {
            setAuthenticatedUser((prevState) => ({
                ...prevState,
                loading,
            }));
        }
        if (data) {
            const meData = _get(data, 'me', {});

            setAuthenticatedUser((prevState) => ({
                ..._merge(prevState, meData),
                isAuthenticated: true,
                loading,
            }));
        }
    }, [called, data, loading]);

    const signOut = () => {
        setAuthenticatedUser(authedUserFieldsObj);
        removeToken();
        presentSuccessToast('You have been signed out');
    };

    return (
        <AuthenticatedUserContext.Provider value={{ authenticatedUser, setAuthenticatedUser, signOut }}>
            <DispatchAuthenticatedUserContext.Provider value={setAuthenticatedUser}>{children}</DispatchAuthenticatedUserContext.Provider>
        </AuthenticatedUserContext.Provider>
    );
};

export default AuthenticatedUserProvider;
