import { ConnectedInputProps, InputModeTypes, InputTypes } from '../types';
import { useController, useFormContext, UseFormReturn } from 'react-hook-form';
import { isEmpty } from 'lodash';
import Input from '../Input';
import React, { FC } from 'react';

const ConnectedInput: FC<ConnectedInputProps> = ({
    registerOptions,
    required,
    name,
    label,
    description,
    type = InputTypes.Text,
    inputMode = InputModeTypes.Text,
    defaultValue = '',
    ...rest
}) => {
    const {
        formState: { errors },
        control,
    }: UseFormReturn = useFormContext();
    const requiredFieldWarningTxt = 'This is Required';

    if (registerOptions?.required) registerOptions.required = requiredFieldWarningTxt;
    if (required && !registerOptions) registerOptions = { required: requiredFieldWarningTxt };

    const {
        field: { onChange, value },
    } = useController({
        name,
        control,
        rules: registerOptions,
        defaultValue,
    });

    const errorMessage = errors[name]?.message ? String(errors[name]?.message) : undefined;

    return (
        <Input
            name={name}
            label={label}
            value={value}
            required={required || !!registerOptions?.required}
            handleChange={onChange}
            type={type}
            inputMode={inputMode}
            description={description}
            showError={!isEmpty(errors[name])}
            errorMessage={errorMessage}
            {...rest}
        />
    );
};

export default ConnectedInput;
