import EmailInput from '../shared/EmailInput';
import Form from '../shared/Form';
import React, { FC } from 'react';
import styles from './styles.module.scss';
import { FieldValues, SubmitHandler } from 'react-hook-form';

type Props = {
    handleSubmit: SubmitHandler<FieldValues>;
};

const ForgotPasswordForm: FC<Props> = ({ handleSubmit }) => (
    <Form submitText='Send link' onSubmit={handleSubmit} className={styles.forgotPasswordForm}>
        <EmailInput name='email' label='Email' required />
    </Form>
);

export default ForgotPasswordForm;
