import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: any; output: any; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any; }
  /** A string used to identify an i18n locale */
  I18NLocaleCode: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  Long: { input: any; output: any; }
  /** A time string with format HH:mm:ss.SSS */
  Time: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String']['output'];
  line1: Scalars['String']['output'];
  line2?: Maybe<Scalars['String']['output']>;
  line3?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type AddressAutoComplete = {
  __typename?: 'AddressAutoComplete';
  matchedAddress: Scalars['String']['output'];
  placeId?: Maybe<Scalars['String']['output']>;
  structuredFormatting?: Maybe<AddressAutoCompleteBreakdown>;
  types: Array<Scalars['String']['output']>;
};

export type AddressAutoCompleteBreakdown = {
  __typename?: 'AddressAutoCompleteBreakdown';
  mainText: Scalars['String']['output'];
  secondaryText: Scalars['String']['output'];
};

export type Bill = {
  __typename?: 'Bill';
  Topic?: Maybe<ComponentTopicsTopics>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  house_vote_date?: Maybe<Scalars['DateTime']['output']>;
  passed_house?: Maybe<Scalars['Boolean']['output']>;
  passed_senate?: Maybe<Scalars['Boolean']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  senate_vote_date?: Maybe<Scalars['DateTime']['output']>;
  source?: Maybe<ENUM_BILL_SOURCE>;
  source_id?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BillEntity = {
  __typename?: 'BillEntity';
  attributes?: Maybe<Bill>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type BillEntityResponse = {
  __typename?: 'BillEntityResponse';
  data?: Maybe<BillEntity>;
};

export type BillEntityResponseCollection = {
  __typename?: 'BillEntityResponseCollection';
  data: Array<BillEntity>;
  meta: ResponseCollectionMeta;
};

export type BillFiltersInput = {
  Topic?: InputMaybe<ComponentTopicsTopicsFiltersInput>;
  _softDeletedAt?: InputMaybe<DateTimeFilterInput>;
  _softDeletedById?: InputMaybe<IntFilterInput>;
  _softDeletedByType?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<BillFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  house_vote_date?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IDFilterInput>;
  not?: InputMaybe<BillFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<BillFiltersInput>>>;
  passed_house?: InputMaybe<BooleanFilterInput>;
  passed_senate?: InputMaybe<BooleanFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  senate_vote_date?: InputMaybe<DateTimeFilterInput>;
  source?: InputMaybe<StringFilterInput>;
  source_id?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type BillInput = {
  Topic?: InputMaybe<ComponentTopicsTopicsInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  house_vote_date?: InputMaybe<Scalars['DateTime']['input']>;
  passed_house?: InputMaybe<Scalars['Boolean']['input']>;
  passed_senate?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  senate_vote_date?: InputMaybe<Scalars['DateTime']['input']>;
  source?: InputMaybe<ENUM_BILL_SOURCE>;
  source_id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type BillRelationResponseCollection = {
  __typename?: 'BillRelationResponseCollection';
  data: Array<BillEntity>;
};

export type BooleanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  contains?: InputMaybe<Scalars['Boolean']['input']>;
  containsi?: InputMaybe<Scalars['Boolean']['input']>;
  endsWith?: InputMaybe<Scalars['Boolean']['input']>;
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  eqi?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['Boolean']['input']>;
  gte?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  lt?: InputMaybe<Scalars['Boolean']['input']>;
  lte?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
  nei?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<BooleanFilterInput>;
  notContains?: InputMaybe<Scalars['Boolean']['input']>;
  notContainsi?: InputMaybe<Scalars['Boolean']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  startsWith?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ComponentContactsContacts = {
  __typename?: 'ComponentContactsContacts';
  id: Scalars['ID']['output'];
  type?: Maybe<ENUM_COMPONENTCONTACTSCONTACTS_TYPE>;
  value?: Maybe<Scalars['String']['output']>;
};

export type ComponentContactsContactsFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentContactsContactsFiltersInput>>>;
  not?: InputMaybe<ComponentContactsContactsFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentContactsContactsFiltersInput>>>;
  type?: InputMaybe<StringFilterInput>;
  value?: InputMaybe<StringFilterInput>;
};

export type ComponentContactsContactsInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<ENUM_COMPONENTCONTACTSCONTACTS_TYPE>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSocialSocial = {
  __typename?: 'ComponentSocialSocial';
  id: Scalars['ID']['output'];
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type ComponentSocialSocialFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSocialSocialFiltersInput>>>;
  not?: InputMaybe<ComponentSocialSocialFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSocialSocialFiltersInput>>>;
  type?: InputMaybe<StringFilterInput>;
  value?: InputMaybe<StringFilterInput>;
};

export type ComponentSocialSocialInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentTopicsTopics = {
  __typename?: 'ComponentTopicsTopics';
  id: Scalars['ID']['output'];
  name?: Maybe<ENUM_COMPONENTTOPICSTOPICS_NAME>;
};

export type ComponentTopicsTopicsFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentTopicsTopicsFiltersInput>>>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentTopicsTopicsFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentTopicsTopicsFiltersInput>>>;
};

export type ComponentTopicsTopicsInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<ENUM_COMPONENTTOPICSTOPICS_NAME>;
};

export type DateFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  contains?: InputMaybe<Scalars['Date']['input']>;
  containsi?: InputMaybe<Scalars['Date']['input']>;
  endsWith?: InputMaybe<Scalars['Date']['input']>;
  eq?: InputMaybe<Scalars['Date']['input']>;
  eqi?: InputMaybe<Scalars['Date']['input']>;
  gt?: InputMaybe<Scalars['Date']['input']>;
  gte?: InputMaybe<Scalars['Date']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  lt?: InputMaybe<Scalars['Date']['input']>;
  lte?: InputMaybe<Scalars['Date']['input']>;
  ne?: InputMaybe<Scalars['Date']['input']>;
  nei?: InputMaybe<Scalars['Date']['input']>;
  not?: InputMaybe<DateFilterInput>;
  notContains?: InputMaybe<Scalars['Date']['input']>;
  notContainsi?: InputMaybe<Scalars['Date']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  startsWith?: InputMaybe<Scalars['Date']['input']>;
};

export type DateTimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  contains?: InputMaybe<Scalars['DateTime']['input']>;
  containsi?: InputMaybe<Scalars['DateTime']['input']>;
  endsWith?: InputMaybe<Scalars['DateTime']['input']>;
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  eqi?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  ne?: InputMaybe<Scalars['DateTime']['input']>;
  nei?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<DateTimeFilterInput>;
  notContains?: InputMaybe<Scalars['DateTime']['input']>;
  notContainsi?: InputMaybe<Scalars['DateTime']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  startsWith?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum ENUM_BILL_SOURCE {
  openstates = 'openstates',
  propublica = 'propublica'
}

export enum ENUM_COMPONENTCONTACTSCONTACTS_TYPE {
  address = 'address',
  email = 'email',
  fax = 'fax',
  voice = 'voice'
}

export enum ENUM_COMPONENTTOPICSTOPICS_NAME {
  Corporation_Accountability = 'Corporation_Accountability',
  Environment = 'Environment',
  Foreign_Policy = 'Foreign_Policy',
  Gun_Control = 'Gun_Control',
  Healthcare = 'Healthcare',
  Immigration = 'Immigration',
  Justice_Reform = 'Justice_Reform',
  LGBTQ_Rights = 'LGBTQ_Rights',
  Racial_Equality = 'Racial_Equality',
  Womens_Rights = 'Womens_Rights'
}

export enum ENUM_EVENT_ACTION {
  create = 'create',
  delete = 'delete',
  searchByAddress = 'searchByAddress',
  suggestionApproved = 'suggestionApproved',
  suggestionNotApproved = 'suggestionNotApproved',
  update = 'update'
}

export enum ENUM_EVENT_ENTITY {
  faq = 'faq',
  official = 'official',
  suggestion = 'suggestion'
}

export enum ENUM_OFFICIAL_STATE {
  Alabama = 'Alabama',
  Alaska = 'Alaska',
  Arizona = 'Arizona',
  Arkansas = 'Arkansas',
  California = 'California',
  Colorado = 'Colorado',
  Connecticut = 'Connecticut',
  Delaware = 'Delaware',
  Florida = 'Florida',
  Georgia = 'Georgia',
  Hawaii = 'Hawaii',
  Idaho = 'Idaho',
  Illinois = 'Illinois',
  Indiana = 'Indiana',
  Iowa = 'Iowa',
  Kansas = 'Kansas',
  Kentucky = 'Kentucky',
  Louisiana = 'Louisiana',
  Maine = 'Maine',
  Maryland = 'Maryland',
  Massachusetts = 'Massachusetts',
  Michigan = 'Michigan',
  Minnesota = 'Minnesota',
  Mississippi = 'Mississippi',
  Missouri = 'Missouri',
  Montana = 'Montana',
  Nebraska = 'Nebraska',
  Nevada = 'Nevada',
  New_Hampshire = 'New_Hampshire',
  New_Jersey = 'New_Jersey',
  New_Mexico = 'New_Mexico',
  New_York = 'New_York',
  North_Carolina = 'North_Carolina',
  North_Dakota = 'North_Dakota',
  Ohio = 'Ohio',
  Oklahoma = 'Oklahoma',
  Oregon = 'Oregon',
  Pennsylvania = 'Pennsylvania',
  Rhode_Island = 'Rhode_Island',
  South_Carolina = 'South_Carolina',
  South_Dakota = 'South_Dakota',
  Tennessee = 'Tennessee',
  Texas = 'Texas',
  Utah = 'Utah',
  Vermont = 'Vermont',
  Virginia = 'Virginia',
  Washington = 'Washington',
  West_Virginia = 'West_Virginia',
  Wisconsin = 'Wisconsin',
  Wyoming = 'Wyoming'
}

export enum ENUM_SUGGESTION_SOURCE {
  google = 'google',
  wikidata = 'wikidata'
}

export enum ENUM_SUGGESTION_STATUS {
  approved = 'approved',
  not_approved = 'not_approved',
  pending = 'pending'
}

export enum ENUM_SUGGESTION_TYPE {
  bill = 'bill',
  image = 'image'
}

export enum ENUM_VOTE_DECISION {
  no = 'no',
  none = 'none',
  yes = 'yes'
}

export type ElectedOfficial = {
  __typename?: 'ElectedOfficial';
  active?: Maybe<Scalars['Boolean']['output']>;
  contacts?: Maybe<Array<Maybe<ComponentContactsContacts>>>;
  google_id: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  jurisdiction?: Maybe<JurisdictionEnum>;
  name: Scalars['String']['output'];
  office: Scalars['String']['output'];
  party: Scalars['String']['output'];
  photoUrl?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<UploadFile>;
  social?: Maybe<Array<Maybe<ComponentSocialSocial>>>;
  website?: Maybe<Scalars['String']['output']>;
};

export type Error = {
  __typename?: 'Error';
  code: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type Event = {
  __typename?: 'Event';
  action?: Maybe<ENUM_EVENT_ACTION>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  entity?: Maybe<ENUM_EVENT_ENTITY>;
  entity_id?: Maybe<Scalars['Int']['output']>;
  payload?: Maybe<Scalars['JSON']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EventEntity = {
  __typename?: 'EventEntity';
  attributes?: Maybe<Event>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type EventEntityResponse = {
  __typename?: 'EventEntityResponse';
  data?: Maybe<EventEntity>;
};

export type EventEntityResponseCollection = {
  __typename?: 'EventEntityResponseCollection';
  data: Array<EventEntity>;
  meta: ResponseCollectionMeta;
};

export type EventFiltersInput = {
  _softDeletedAt?: InputMaybe<DateTimeFilterInput>;
  _softDeletedById?: InputMaybe<IntFilterInput>;
  _softDeletedByType?: InputMaybe<StringFilterInput>;
  action?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<EventFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  entity?: InputMaybe<StringFilterInput>;
  entity_id?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IDFilterInput>;
  not?: InputMaybe<EventFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<EventFiltersInput>>>;
  payload?: InputMaybe<JSONFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type EventInput = {
  action?: InputMaybe<ENUM_EVENT_ACTION>;
  entity?: InputMaybe<ENUM_EVENT_ENTITY>;
  entity_id?: InputMaybe<Scalars['Int']['input']>;
  payload?: InputMaybe<Scalars['JSON']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EventRelationResponseCollection = {
  __typename?: 'EventRelationResponseCollection';
  data: Array<EventEntity>;
};

export type Faq = {
  __typename?: 'Faq';
  answer?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FaqEntity = {
  __typename?: 'FaqEntity';
  attributes?: Maybe<Faq>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type FaqEntityResponse = {
  __typename?: 'FaqEntityResponse';
  data?: Maybe<FaqEntity>;
};

export type FaqEntityResponseCollection = {
  __typename?: 'FaqEntityResponseCollection';
  data: Array<FaqEntity>;
  meta: ResponseCollectionMeta;
};

export type FaqFiltersInput = {
  _softDeletedAt?: InputMaybe<DateTimeFilterInput>;
  _softDeletedById?: InputMaybe<IntFilterInput>;
  _softDeletedByType?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<FaqFiltersInput>>>;
  answer?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IDFilterInput>;
  not?: InputMaybe<FaqFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<FaqFiltersInput>>>;
  priority?: InputMaybe<IntFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  question?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type FaqInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
};

export type FaqRelationResponseCollection = {
  __typename?: 'FaqRelationResponseCollection';
  data: Array<FaqEntity>;
};

export type FileInfoInput = {
  alternativeText?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type FloatFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  contains?: InputMaybe<Scalars['Float']['input']>;
  containsi?: InputMaybe<Scalars['Float']['input']>;
  endsWith?: InputMaybe<Scalars['Float']['input']>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  eqi?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['Float']['input']>;
  nei?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<FloatFilterInput>;
  notContains?: InputMaybe<Scalars['Float']['input']>;
  notContainsi?: InputMaybe<Scalars['Float']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  startsWith?: InputMaybe<Scalars['Float']['input']>;
};

export type GenericMorph = Bill | ComponentContactsContacts | ComponentSocialSocial | ComponentTopicsTopics | Event | Faq | I18NLocale | Official | Subscriber | Suggestion | UploadFile | UploadFolder | UsersPermissionsPermission | UsersPermissionsRole | UsersPermissionsUser | Vote;

export type I18NLocale = {
  __typename?: 'I18NLocale';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type I18NLocaleEntity = {
  __typename?: 'I18NLocaleEntity';
  attributes?: Maybe<I18NLocale>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type I18NLocaleEntityResponse = {
  __typename?: 'I18NLocaleEntityResponse';
  data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection';
  data: Array<I18NLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type I18NLocaleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  code?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IDFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<I18NLocaleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type I18NLocaleInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type I18NLocaleRelationResponseCollection = {
  __typename?: 'I18NLocaleRelationResponseCollection';
  data: Array<I18NLocaleEntity>;
};

export type IDFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  contains?: InputMaybe<Scalars['ID']['input']>;
  containsi?: InputMaybe<Scalars['ID']['input']>;
  endsWith?: InputMaybe<Scalars['ID']['input']>;
  eq?: InputMaybe<Scalars['ID']['input']>;
  eqi?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  gte?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  lte?: InputMaybe<Scalars['ID']['input']>;
  ne?: InputMaybe<Scalars['ID']['input']>;
  nei?: InputMaybe<Scalars['ID']['input']>;
  not?: InputMaybe<IDFilterInput>;
  notContains?: InputMaybe<Scalars['ID']['input']>;
  notContainsi?: InputMaybe<Scalars['ID']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  startsWith?: InputMaybe<Scalars['ID']['input']>;
};

export type IntFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  contains?: InputMaybe<Scalars['Int']['input']>;
  containsi?: InputMaybe<Scalars['Int']['input']>;
  endsWith?: InputMaybe<Scalars['Int']['input']>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  eqi?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
  nei?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<IntFilterInput>;
  notContains?: InputMaybe<Scalars['Int']['input']>;
  notContainsi?: InputMaybe<Scalars['Int']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  startsWith?: InputMaybe<Scalars['Int']['input']>;
};

export type JSONFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  contains?: InputMaybe<Scalars['JSON']['input']>;
  containsi?: InputMaybe<Scalars['JSON']['input']>;
  endsWith?: InputMaybe<Scalars['JSON']['input']>;
  eq?: InputMaybe<Scalars['JSON']['input']>;
  eqi?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  ne?: InputMaybe<Scalars['JSON']['input']>;
  nei?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<JSONFilterInput>;
  notContains?: InputMaybe<Scalars['JSON']['input']>;
  notContainsi?: InputMaybe<Scalars['JSON']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  startsWith?: InputMaybe<Scalars['JSON']['input']>;
};

export enum JurisdictionEnum {
  CITY = 'CITY',
  COUNTY = 'COUNTY',
  FEDERAL = 'FEDERAL',
  STATE = 'STATE'
}

export type JurisdictionLabel = {
  __typename?: 'JurisdictionLabel';
  label: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type LongFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  contains?: InputMaybe<Scalars['Long']['input']>;
  containsi?: InputMaybe<Scalars['Long']['input']>;
  endsWith?: InputMaybe<Scalars['Long']['input']>;
  eq?: InputMaybe<Scalars['Long']['input']>;
  eqi?: InputMaybe<Scalars['Long']['input']>;
  gt?: InputMaybe<Scalars['Long']['input']>;
  gte?: InputMaybe<Scalars['Long']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  lt?: InputMaybe<Scalars['Long']['input']>;
  lte?: InputMaybe<Scalars['Long']['input']>;
  ne?: InputMaybe<Scalars['Long']['input']>;
  nei?: InputMaybe<Scalars['Long']['input']>;
  not?: InputMaybe<LongFilterInput>;
  notContains?: InputMaybe<Scalars['Long']['input']>;
  notContainsi?: InputMaybe<Scalars['Long']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  startsWith?: InputMaybe<Scalars['Long']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<UsersPermissionsLoginPayload>;
  createBill?: Maybe<BillEntityResponse>;
  createEvent?: Maybe<EventEntityResponse>;
  createFaq?: Maybe<FaqEntityResponse>;
  createOfficial?: Maybe<OfficialEntityResponse>;
  createSubscriber?: Maybe<SubscriberEntityResponse>;
  createSuggestion?: Maybe<SuggestionEntityResponse>;
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  createUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  createVote?: Maybe<VoteEntityResponse>;
  deleteBill?: Maybe<BillEntityResponse>;
  deleteEvent?: Maybe<EventEntityResponse>;
  deleteFaq?: Maybe<FaqEntityResponse>;
  deleteMe?: Maybe<Scalars['Boolean']['output']>;
  deleteOfficial?: Maybe<OfficialEntityResponse>;
  deleteSubscriber?: Maybe<SubscriberEntityResponse>;
  deleteSuggestion?: Maybe<SuggestionEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deleteVote?: Maybe<VoteEntityResponse>;
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  login: UsersPermissionsLoginPayload;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  removeFile?: Maybe<UploadFileEntityResponse>;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  sendConfirmationEmail?: Maybe<Scalars['Boolean']['output']>;
  updateBill?: Maybe<BillEntityResponse>;
  updateEvent?: Maybe<EventEntityResponse>;
  updateFaq?: Maybe<FaqEntityResponse>;
  updateFileInfo: UploadFileEntityResponse;
  updateMe?: Maybe<UsersPermissionsUser>;
  updateOfficial?: Maybe<OfficialEntityResponse>;
  updateSubscriber?: Maybe<SubscriberEntityResponse>;
  updateSuggestion?: Maybe<SuggestionEntityResponse>;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  updateVote?: Maybe<VoteEntityResponse>;
  upload: UploadFileEntityResponse;
};


export type MutationchangePasswordArgs = {
  currentPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};


export type MutationcreateBillArgs = {
  data: BillInput;
};


export type MutationcreateEventArgs = {
  data: EventInput;
};


export type MutationcreateFaqArgs = {
  data: FaqInput;
};


export type MutationcreateOfficialArgs = {
  data: OfficialInput;
};


export type MutationcreateSubscriberArgs = {
  data: SubscriberInput;
};


export type MutationcreateSuggestionArgs = {
  data: SuggestionInput;
};


export type MutationcreateUploadFileArgs = {
  data: UploadFileInput;
};


export type MutationcreateUploadFolderArgs = {
  data: UploadFolderInput;
};


export type MutationcreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};


export type MutationcreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};


export type MutationcreateVoteArgs = {
  data: VoteInput;
};


export type MutationdeleteBillArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdeleteEventArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdeleteFaqArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdeleteOfficialArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdeleteSubscriberArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdeleteSuggestionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdeleteUploadFileArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdeleteUploadFolderArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdeleteUsersPermissionsUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdeleteVoteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationemailConfirmationArgs = {
  confirmation: Scalars['String']['input'];
};


export type MutationforgotPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationloginArgs = {
  input: UsersPermissionsLoginInput;
};


export type MutationmultipleUploadArgs = {
  field?: InputMaybe<Scalars['String']['input']>;
  files: Array<InputMaybe<Scalars['Upload']['input']>>;
  ref?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationregisterArgs = {
  input: UsersPermissionsRegisterInput;
};


export type MutationremoveFileArgs = {
  id: Scalars['ID']['input'];
};


export type MutationresetPasswordArgs = {
  code: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};


export type MutationsendConfirmationEmailArgs = {
  email: Scalars['String']['input'];
};


export type MutationupdateBillArgs = {
  data: BillInput;
  id: Scalars['ID']['input'];
};


export type MutationupdateEventArgs = {
  data: EventInput;
  id: Scalars['ID']['input'];
};


export type MutationupdateFaqArgs = {
  data: FaqInput;
  id: Scalars['ID']['input'];
};


export type MutationupdateFileInfoArgs = {
  id: Scalars['ID']['input'];
  info?: InputMaybe<FileInfoInput>;
};


export type MutationupdateMeArgs = {
  input: UsersPermissionsUserInput;
};


export type MutationupdateOfficialArgs = {
  data: OfficialInput;
  id: Scalars['ID']['input'];
};


export type MutationupdateSubscriberArgs = {
  data: SubscriberInput;
  id: Scalars['ID']['input'];
};


export type MutationupdateSuggestionArgs = {
  data: SuggestionInput;
  id: Scalars['ID']['input'];
};


export type MutationupdateUploadFileArgs = {
  data: UploadFileInput;
  id: Scalars['ID']['input'];
};


export type MutationupdateUploadFolderArgs = {
  data: UploadFolderInput;
  id: Scalars['ID']['input'];
};


export type MutationupdateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
  id: Scalars['ID']['input'];
};


export type MutationupdateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
  id: Scalars['ID']['input'];
};


export type MutationupdateVoteArgs = {
  data: VoteInput;
  id: Scalars['ID']['input'];
};


export type MutationuploadArgs = {
  field?: InputMaybe<Scalars['String']['input']>;
  file: Scalars['Upload']['input'];
  info?: InputMaybe<FileInfoInput>;
  ref?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
};

export type Official = {
  __typename?: 'Official';
  active?: Maybe<Scalars['Boolean']['output']>;
  contacts?: Maybe<Array<Maybe<ComponentContactsContacts>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  office?: Maybe<Scalars['String']['output']>;
  openstates_id?: Maybe<Scalars['String']['output']>;
  party?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<UploadFileEntityResponse>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  social?: Maybe<Array<Maybe<ComponentSocialSocial>>>;
  state?: Maybe<ENUM_OFFICIAL_STATE>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};


export type OfficialcontactsArgs = {
  filters?: InputMaybe<ComponentContactsContactsFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type OfficialsocialArgs = {
  filters?: InputMaybe<ComponentSocialSocialFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type OfficialEntity = {
  __typename?: 'OfficialEntity';
  attributes?: Maybe<Official>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type OfficialEntityResponse = {
  __typename?: 'OfficialEntityResponse';
  data?: Maybe<OfficialEntity>;
};

export type OfficialEntityResponseCollection = {
  __typename?: 'OfficialEntityResponseCollection';
  data: Array<OfficialEntity>;
  meta: ResponseCollectionMeta;
};

export type OfficialFiltersInput = {
  _softDeletedAt?: InputMaybe<DateTimeFilterInput>;
  _softDeletedById?: InputMaybe<IntFilterInput>;
  _softDeletedByType?: InputMaybe<StringFilterInput>;
  active?: InputMaybe<BooleanFilterInput>;
  and?: InputMaybe<Array<InputMaybe<OfficialFiltersInput>>>;
  bio_id?: InputMaybe<StringFilterInput>;
  contacts?: InputMaybe<ComponentContactsContactsFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  division?: InputMaybe<StringFilterInput>;
  google_id?: InputMaybe<LongFilterInput>;
  id?: InputMaybe<IDFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<OfficialFiltersInput>;
  office?: InputMaybe<StringFilterInput>;
  openstates_id?: InputMaybe<StringFilterInput>;
  or?: InputMaybe<Array<InputMaybe<OfficialFiltersInput>>>;
  party?: InputMaybe<StringFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  social?: InputMaybe<ComponentSocialSocialFiltersInput>;
  state?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  website?: InputMaybe<StringFilterInput>;
};

export type OfficialInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  bio_id?: InputMaybe<Scalars['String']['input']>;
  contacts?: InputMaybe<Array<InputMaybe<ComponentContactsContactsInput>>>;
  division?: InputMaybe<Scalars['String']['input']>;
  google_id?: InputMaybe<Scalars['Long']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<Scalars['String']['input']>;
  openstates_id?: InputMaybe<Scalars['String']['input']>;
  party?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  social?: InputMaybe<Array<InputMaybe<ComponentSocialSocialInput>>>;
  state?: InputMaybe<ENUM_OFFICIAL_STATE>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type OfficialRelationResponseCollection = {
  __typename?: 'OfficialRelationResponseCollection';
  data: Array<OfficialEntity>;
};

export type OfficialsByAddressResponse = {
  __typename?: 'OfficialsByAddressResponse';
  address?: Maybe<Address>;
  jurisdictionLabels: Array<Maybe<JurisdictionLabel>>;
  officials: Array<Maybe<ElectedOfficial>>;
};

export type Pagination = {
  __typename?: 'Pagination';
  page: Scalars['Int']['output'];
  pageCount: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginationArg = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export enum PublicationState {
  LIVE = 'LIVE',
  PREVIEW = 'PREVIEW'
}

export type Query = {
  __typename?: 'Query';
  bill?: Maybe<BillEntityResponse>;
  bills?: Maybe<BillEntityResponseCollection>;
  event?: Maybe<EventEntityResponse>;
  events?: Maybe<EventEntityResponseCollection>;
  faq?: Maybe<FaqEntityResponse>;
  faqs?: Maybe<FaqEntityResponseCollection>;
  fetchAutoCompleteAddresses: Array<Maybe<AddressAutoComplete>>;
  fetchOfficialsByAddress?: Maybe<OfficialsByAddressResponse>;
  i18NLocale?: Maybe<I18NLocaleEntityResponse>;
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
  me?: Maybe<UsersPermissionsMe>;
  official?: Maybe<OfficialEntityResponse>;
  officials?: Maybe<OfficialEntityResponseCollection>;
  subscriber?: Maybe<SubscriberEntityResponse>;
  subscribers?: Maybe<SubscriberEntityResponseCollection>;
  suggestion?: Maybe<SuggestionEntityResponse>;
  suggestions?: Maybe<SuggestionEntityResponseCollection>;
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder?: Maybe<UploadFolderEntityResponse>;
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
  vote?: Maybe<VoteEntityResponse>;
  votes?: Maybe<VoteEntityResponseCollection>;
};


export type QuerybillArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerybillsArgs = {
  filters?: InputMaybe<BillFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryeventArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryeventsArgs = {
  filters?: InputMaybe<EventFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryfaqArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryfaqsArgs = {
  filters?: InputMaybe<FaqFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryfetchAutoCompleteAddressesArgs = {
  query: Scalars['String']['input'];
};


export type QueryfetchOfficialsByAddressArgs = {
  address: Scalars['String']['input'];
};


export type Queryi18NLocaleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type Queryi18NLocalesArgs = {
  filters?: InputMaybe<I18NLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryofficialArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryofficialsArgs = {
  filters?: InputMaybe<OfficialFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QuerysubscriberArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerysubscribersArgs = {
  filters?: InputMaybe<SubscriberFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QuerysuggestionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerysuggestionsArgs = {
  filters?: InputMaybe<SuggestionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryuploadFileArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryuploadFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryuploadFolderArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryuploadFoldersArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryusersPermissionsRoleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryusersPermissionsRolesArgs = {
  filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryusersPermissionsUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryusersPermissionsUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryvoteArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryvotesArgs = {
  filters?: InputMaybe<VoteFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta';
  pagination: Pagination;
};

export type StringFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  containsi?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  eqi?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nei?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<StringFilterInput>;
  notContains?: InputMaybe<Scalars['String']['input']>;
  notContainsi?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Subscriber = {
  __typename?: 'Subscriber';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SubscriberEntity = {
  __typename?: 'SubscriberEntity';
  attributes?: Maybe<Subscriber>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SubscriberEntityResponse = {
  __typename?: 'SubscriberEntityResponse';
  data?: Maybe<SubscriberEntity>;
};

export type SubscriberEntityResponseCollection = {
  __typename?: 'SubscriberEntityResponseCollection';
  data: Array<SubscriberEntity>;
  meta: ResponseCollectionMeta;
};

export type SubscriberFiltersInput = {
  _softDeletedAt?: InputMaybe<DateTimeFilterInput>;
  _softDeletedById?: InputMaybe<IntFilterInput>;
  _softDeletedByType?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<SubscriberFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IDFilterInput>;
  not?: InputMaybe<SubscriberFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SubscriberFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SubscriberInput = {
  email?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriberRelationResponseCollection = {
  __typename?: 'SubscriberRelationResponseCollection';
  data: Array<SubscriberEntity>;
};

export type Suggestion = {
  __typename?: 'Suggestion';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  link_url?: Maybe<Scalars['String']['output']>;
  official_bio_id?: Maybe<Scalars['String']['output']>;
  official_google_id?: Maybe<Scalars['Long']['output']>;
  official_name?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  source?: Maybe<ENUM_SUGGESTION_SOURCE>;
  source_url?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ENUM_SUGGESTION_STATUS>;
  type?: Maybe<ENUM_SUGGESTION_TYPE>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<UsersPermissionsUserEntityResponse>;
};

export type SuggestionEntity = {
  __typename?: 'SuggestionEntity';
  attributes?: Maybe<Suggestion>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SuggestionEntityResponse = {
  __typename?: 'SuggestionEntityResponse';
  data?: Maybe<SuggestionEntity>;
};

export type SuggestionEntityResponseCollection = {
  __typename?: 'SuggestionEntityResponseCollection';
  data: Array<SuggestionEntity>;
  meta: ResponseCollectionMeta;
};

export type SuggestionFiltersInput = {
  _softDeletedAt?: InputMaybe<DateTimeFilterInput>;
  _softDeletedById?: InputMaybe<IntFilterInput>;
  _softDeletedByType?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<SuggestionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IDFilterInput>;
  link_url?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<SuggestionFiltersInput>;
  official_bio_id?: InputMaybe<StringFilterInput>;
  official_google_id?: InputMaybe<LongFilterInput>;
  official_name?: InputMaybe<StringFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SuggestionFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  remarks?: InputMaybe<StringFilterInput>;
  source?: InputMaybe<StringFilterInput>;
  source_url?: InputMaybe<StringFilterInput>;
  status?: InputMaybe<StringFilterInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  user?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type SuggestionInput = {
  link_url?: InputMaybe<Scalars['String']['input']>;
  official_bio_id?: InputMaybe<Scalars['String']['input']>;
  official_google_id?: InputMaybe<Scalars['Long']['input']>;
  official_name?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<ENUM_SUGGESTION_SOURCE>;
  source_url?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ENUM_SUGGESTION_STATUS>;
  type?: InputMaybe<ENUM_SUGGESTION_TYPE>;
  user?: InputMaybe<Scalars['ID']['input']>;
};

export type SuggestionRelationResponseCollection = {
  __typename?: 'SuggestionRelationResponseCollection';
  data: Array<SuggestionEntity>;
};

export type TimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Time']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Time']['input']>>>;
  contains?: InputMaybe<Scalars['Time']['input']>;
  containsi?: InputMaybe<Scalars['Time']['input']>;
  endsWith?: InputMaybe<Scalars['Time']['input']>;
  eq?: InputMaybe<Scalars['Time']['input']>;
  eqi?: InputMaybe<Scalars['Time']['input']>;
  gt?: InputMaybe<Scalars['Time']['input']>;
  gte?: InputMaybe<Scalars['Time']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Time']['input']>>>;
  lt?: InputMaybe<Scalars['Time']['input']>;
  lte?: InputMaybe<Scalars['Time']['input']>;
  ne?: InputMaybe<Scalars['Time']['input']>;
  nei?: InputMaybe<Scalars['Time']['input']>;
  not?: InputMaybe<TimeFilterInput>;
  notContains?: InputMaybe<Scalars['Time']['input']>;
  notContainsi?: InputMaybe<Scalars['Time']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Time']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Time']['input']>>>;
  startsWith?: InputMaybe<Scalars['Time']['input']>;
};

export type UploadFile = {
  __typename?: 'UploadFile';
  alternativeText?: Maybe<Scalars['String']['output']>;
  caption?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  ext?: Maybe<Scalars['String']['output']>;
  formats?: Maybe<Scalars['JSON']['output']>;
  hash: Scalars['String']['output'];
  height?: Maybe<Scalars['Int']['output']>;
  mime: Scalars['String']['output'];
  name: Scalars['String']['output'];
  previewUrl?: Maybe<Scalars['String']['output']>;
  provider: Scalars['String']['output'];
  provider_metadata?: Maybe<Scalars['JSON']['output']>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  size: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  width?: Maybe<Scalars['Int']['output']>;
};

export type UploadFileEntity = {
  __typename?: 'UploadFileEntity';
  attributes?: Maybe<UploadFile>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse';
  data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection';
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFileFiltersInput = {
  alternativeText?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  caption?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ext?: InputMaybe<StringFilterInput>;
  folder?: InputMaybe<UploadFolderFiltersInput>;
  folderPath?: InputMaybe<StringFilterInput>;
  formats?: InputMaybe<JSONFilterInput>;
  hash?: InputMaybe<StringFilterInput>;
  height?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IDFilterInput>;
  mime?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFileFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  previewUrl?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  provider_metadata?: InputMaybe<JSONFilterInput>;
  size?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
  width?: InputMaybe<IntFilterInput>;
};

export type UploadFileInput = {
  alternativeText?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  ext?: InputMaybe<Scalars['String']['input']>;
  folder?: InputMaybe<Scalars['ID']['input']>;
  folderPath?: InputMaybe<Scalars['String']['input']>;
  formats?: InputMaybe<Scalars['JSON']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  mime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  previewUrl?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  provider_metadata?: InputMaybe<Scalars['JSON']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<UploadFileEntity>;
};

export type UploadFolder = {
  __typename?: 'UploadFolder';
  children?: Maybe<UploadFolderRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  name: Scalars['String']['output'];
  parent?: Maybe<UploadFolderEntityResponse>;
  path: Scalars['String']['output'];
  pathId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type UploadFolderchildrenArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type UploadFolderfilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UploadFolderEntity = {
  __typename?: 'UploadFolderEntity';
  attributes?: Maybe<UploadFolder>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UploadFolderEntityResponse = {
  __typename?: 'UploadFolderEntityResponse';
  data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
  __typename?: 'UploadFolderEntityResponseCollection';
  data: Array<UploadFolderEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFolderFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  children?: InputMaybe<UploadFolderFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  files?: InputMaybe<UploadFileFiltersInput>;
  id?: InputMaybe<IDFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFolderFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  parent?: InputMaybe<UploadFolderFiltersInput>;
  path?: InputMaybe<StringFilterInput>;
  pathId?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UploadFolderInput = {
  children?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['ID']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  pathId?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadFolderRelationResponseCollection = {
  __typename?: 'UploadFolderRelationResponseCollection';
  data: Array<UploadFolderEntity>;
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String']['input'];
  password: Scalars['String']['input'];
  provider?: Scalars['String']['input'];
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']['output']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  address?: Maybe<Scalars['String']['output']>;
  blocked?: Maybe<Scalars['Boolean']['output']>;
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  last_name?: Maybe<Scalars['String']['output']>;
  role?: Maybe<UsersPermissionsMeRole>;
  username: Scalars['String']['output'];
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  action: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity';
  attributes?: Maybe<UsersPermissionsPermission>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsPermissionEntityResponse = {
  __typename?: 'UsersPermissionsPermissionEntityResponse';
  data?: Maybe<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsPermissionEntityResponseCollection = {
  __typename?: 'UsersPermissionsPermissionEntityResponseCollection';
  data: Array<UsersPermissionsPermissionEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsPermissionFiltersInput = {
  action?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IDFilterInput>;
  not?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UsersPermissionsPermissionInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['ID']['input']>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection';
  data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRegisterInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};


export type UsersPermissionsRolepermissionsArgs = {
  filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type UsersPermissionsRoleusersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity';
  attributes?: Maybe<UsersPermissionsRole>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse';
  data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsRoleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IDFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type UsersPermissionsRoleRelationResponseCollection = {
  __typename?: 'UsersPermissionsRoleRelationResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  address?: Maybe<Scalars['String']['output']>;
  blocked?: Maybe<Scalars['Boolean']['output']>;
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  first_name?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  suggestion?: Maybe<SuggestionEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username: Scalars['String']['output'];
};

export type UsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity';
  attributes?: Maybe<UsersPermissionsUser>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse';
  data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
  address?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  blocked?: InputMaybe<BooleanFilterInput>;
  confirmationToken?: InputMaybe<StringFilterInput>;
  confirmed?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  first_name?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IDFilterInput>;
  last_name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsUserFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  password?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  resetPasswordToken?: InputMaybe<StringFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  suggestion?: InputMaybe<SuggestionFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  username?: InputMaybe<StringFilterInput>;
};

export type UsersPermissionsUserInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  confirmationToken?: InputMaybe<Scalars['String']['input']>;
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  oldPassword?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  resetPasswordToken?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['ID']['input']>;
  suggestion?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
};

export type Vote = {
  __typename?: 'Vote';
  bill?: Maybe<BillEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  decision?: Maybe<ENUM_VOTE_DECISION>;
  official?: Maybe<OfficialEntityResponse>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  vote_date?: Maybe<Scalars['DateTime']['output']>;
};

export type VoteEntity = {
  __typename?: 'VoteEntity';
  attributes?: Maybe<Vote>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type VoteEntityResponse = {
  __typename?: 'VoteEntityResponse';
  data?: Maybe<VoteEntity>;
};

export type VoteEntityResponseCollection = {
  __typename?: 'VoteEntityResponseCollection';
  data: Array<VoteEntity>;
  meta: ResponseCollectionMeta;
};

export type VoteFiltersInput = {
  _softDeletedAt?: InputMaybe<DateTimeFilterInput>;
  _softDeletedById?: InputMaybe<IntFilterInput>;
  _softDeletedByType?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<VoteFiltersInput>>>;
  bill?: InputMaybe<BillFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  decision?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IDFilterInput>;
  not?: InputMaybe<VoteFiltersInput>;
  official?: InputMaybe<OfficialFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<VoteFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  vote_date?: InputMaybe<DateTimeFilterInput>;
};

export type VoteInput = {
  bill?: InputMaybe<Scalars['ID']['input']>;
  decision?: InputMaybe<ENUM_VOTE_DECISION>;
  official?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  vote_date?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VoteRelationResponseCollection = {
  __typename?: 'VoteRelationResponseCollection';
  data: Array<VoteEntity>;
};

export type UpdateUserInfoMutationVariables = Exact<{
  address: Scalars['String']['input'];
  email: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
}>;


export type UpdateUserInfoMutation = { __typename?: 'Mutation', updateMe?: { __typename?: 'UsersPermissionsUser', email: string, username: string, address?: string | null, first_name?: string | null, last_name?: string | null } | null };

export type UpdateUserPasswordMutationVariables = Exact<{
  password: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
}>;


export type UpdateUserPasswordMutation = { __typename?: 'Mutation', updateMe?: { __typename?: 'UsersPermissionsUser', email: string } | null };

export type authenticatedUserFieldsFragment = { __typename?: 'UsersPermissionsMe', id: string, username: string, email?: string | null, confirmed?: boolean | null, address?: string | null, first_name?: string | null, last_name?: string | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'UsersPermissionsMe', id: string, username: string, email?: string | null, confirmed?: boolean | null, address?: string | null, first_name?: string | null, last_name?: string | null } | null };

export type DeleteUserMutationVariables = Exact<{
  input: Scalars['ID']['input'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteMe?: boolean | null };

export type ResendEmailConfirmationMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ResendEmailConfirmationMutation = { __typename?: 'Mutation', sendConfirmationEmail?: boolean | null };

export type FaqsQueryVariables = Exact<{ [key: string]: never; }>;


export type FaqsQuery = { __typename?: 'Query', faqs?: { __typename?: 'FaqEntityResponseCollection', data: Array<{ __typename?: 'FaqEntity', attributes?: { __typename?: 'Faq', question?: string | null, answer?: string | null } | null }> } | null };

export type SubmitSubscriberMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type SubmitSubscriberMutation = { __typename?: 'Mutation', createSubscriber?: { __typename?: 'SubscriberEntityResponse', data?: { __typename?: 'SubscriberEntity', id?: string | null } | null } | null };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword?: { __typename?: 'UsersPermissionsPasswordPayload', ok: boolean } | null };

export type FetchOfficialsByAddressQueryVariables = Exact<{
  address: Scalars['String']['input'];
}>;


export type FetchOfficialsByAddressQuery = { __typename?: 'Query', fetchOfficialsByAddress?: { __typename?: 'OfficialsByAddressResponse', address?: { __typename?: 'Address', state: string } | null, jurisdictionLabels: Array<{ __typename?: 'JurisdictionLabel', type: string, label: string } | null>, officials: Array<{ __typename?: 'ElectedOfficial', id?: string | null, google_id: string, name: string, photoUrl?: string | null, office: string, jurisdiction?: JurisdictionEnum | null, party: string, website?: string | null, active?: boolean | null, picture?: { __typename?: 'UploadFile', url: string, formats?: any | null } | null, contacts?: Array<{ __typename?: 'ComponentContactsContacts', type?: ENUM_COMPONENTCONTACTSCONTACTS_TYPE | null, value?: string | null } | null> | null, social?: Array<{ __typename?: 'ComponentSocialSocial', value?: string | null, type?: string | null } | null> | null } | null> } | null };

export type OfficialQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type OfficialQuery = { __typename?: 'Query', official?: { __typename?: 'OfficialEntityResponse', data?: { __typename?: 'OfficialEntity', id?: string | null, attributes?: { __typename?: 'Official', name?: string | null, office?: string | null, party?: string | null, social?: Array<{ __typename?: 'ComponentSocialSocial', value?: string | null, type?: string | null } | null> | null, picture?: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', attributes?: { __typename?: 'UploadFile', url: string, formats?: any | null } | null } | null } | null, contacts?: Array<{ __typename?: 'ComponentContactsContacts', type?: ENUM_COMPONENTCONTACTSCONTACTS_TYPE | null, value?: string | null } | null> | null } | null } | null } | null };

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  code: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword?: { __typename?: 'UsersPermissionsLoginPayload', user: { __typename?: 'UsersPermissionsMe', id: string, username: string, email?: string | null } } | null };

export type loginMutationVariables = Exact<{
  identifier: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type loginMutation = { __typename?: 'Mutation', login: { __typename?: 'UsersPermissionsLoginPayload', jwt?: string | null, user: { __typename?: 'UsersPermissionsMe', id: string, username: string, email?: string | null, confirmed?: boolean | null, address?: string | null, first_name?: string | null, last_name?: string | null } } };

export type RegisterMutationVariables = Exact<{
  username: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  address: Scalars['String']['input'];
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'UsersPermissionsLoginPayload', user: { __typename?: 'UsersPermissionsMe', id: string, email?: string | null } } };

export type AutoCompleteQueryVariables = Exact<{
  query: Scalars['String']['input'];
}>;


export type AutoCompleteQuery = { __typename?: 'Query', fetchAutoCompleteAddresses: Array<{ __typename?: 'AddressAutoComplete', matchedAddress: string } | null> };

export type SubmitImageSuggestionMutationVariables = Exact<{
  image_source: Scalars['String']['input'];
  image_link: Scalars['String']['input'];
  name: Scalars['String']['input'];
  google_id: Scalars['Long']['input'];
  user: Scalars['ID']['input'];
}>;


export type SubmitImageSuggestionMutation = { __typename?: 'Mutation', createSuggestion?: { __typename?: 'SuggestionEntityResponse', data?: { __typename?: 'SuggestionEntity', id?: string | null } | null } | null };

export const authenticatedUserFieldsFragmentDoc = gql`
    fragment authenticatedUserFields on UsersPermissionsMe {
  id
  username
  email
  confirmed
  address
  first_name
  last_name
}
    `;
export const UpdateUserInfoDocument = gql`
    mutation UpdateUserInfo($address: String!, $email: String!, $first_name: String!, $last_name: String!) {
  updateMe(
    input: {address: $address, email: $email, first_name: $first_name, last_name: $last_name}
  ) {
    email
    username
    address
    first_name
    last_name
  }
}
    `;
export type UpdateUserInfoMutationFn = Apollo.MutationFunction<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>;

/**
 * __useUpdateUserInfoMutation__
 *
 * To run a mutation, you first call `useUpdateUserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserInfoMutation, { data, loading, error }] = useUpdateUserInfoMutation({
 *   variables: {
 *      address: // value for 'address'
 *      email: // value for 'email'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *   },
 * });
 */
export function useUpdateUserInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>(UpdateUserInfoDocument, options);
      }
export type UpdateUserInfoMutationHookResult = ReturnType<typeof useUpdateUserInfoMutation>;
export type UpdateUserInfoMutationResult = Apollo.MutationResult<UpdateUserInfoMutation>;
export type UpdateUserInfoMutationOptions = Apollo.BaseMutationOptions<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>;
export const UpdateUserPasswordDocument = gql`
    mutation UpdateUserPassword($password: String!, $oldPassword: String!) {
  updateMe(input: {password: $password, oldPassword: $oldPassword}) {
    email
  }
}
    `;
export type UpdateUserPasswordMutationFn = Apollo.MutationFunction<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>;

/**
 * __useUpdateUserPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPasswordMutation, { data, loading, error }] = useUpdateUserPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      oldPassword: // value for 'oldPassword'
 *   },
 * });
 */
export function useUpdateUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>(UpdateUserPasswordDocument, options);
      }
export type UpdateUserPasswordMutationHookResult = ReturnType<typeof useUpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationResult = Apollo.MutationResult<UpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationOptions = Apollo.BaseMutationOptions<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...authenticatedUserFields
  }
}
    ${authenticatedUserFieldsFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($input: ID!) {
  deleteMe
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const ResendEmailConfirmationDocument = gql`
    mutation ResendEmailConfirmation($email: String!) {
  sendConfirmationEmail(email: $email)
}
    `;
export type ResendEmailConfirmationMutationFn = Apollo.MutationFunction<ResendEmailConfirmationMutation, ResendEmailConfirmationMutationVariables>;

/**
 * __useResendEmailConfirmationMutation__
 *
 * To run a mutation, you first call `useResendEmailConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendEmailConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendEmailConfirmationMutation, { data, loading, error }] = useResendEmailConfirmationMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendEmailConfirmationMutation(baseOptions?: Apollo.MutationHookOptions<ResendEmailConfirmationMutation, ResendEmailConfirmationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendEmailConfirmationMutation, ResendEmailConfirmationMutationVariables>(ResendEmailConfirmationDocument, options);
      }
export type ResendEmailConfirmationMutationHookResult = ReturnType<typeof useResendEmailConfirmationMutation>;
export type ResendEmailConfirmationMutationResult = Apollo.MutationResult<ResendEmailConfirmationMutation>;
export type ResendEmailConfirmationMutationOptions = Apollo.BaseMutationOptions<ResendEmailConfirmationMutation, ResendEmailConfirmationMutationVariables>;
export const FaqsDocument = gql`
    query Faqs {
  faqs(sort: "priority") {
    data {
      attributes {
        question
        answer
      }
    }
  }
}
    `;

/**
 * __useFaqsQuery__
 *
 * To run a query within a React component, call `useFaqsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaqsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaqsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFaqsQuery(baseOptions?: Apollo.QueryHookOptions<FaqsQuery, FaqsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FaqsQuery, FaqsQueryVariables>(FaqsDocument, options);
      }
export function useFaqsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FaqsQuery, FaqsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FaqsQuery, FaqsQueryVariables>(FaqsDocument, options);
        }
export function useFaqsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FaqsQuery, FaqsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FaqsQuery, FaqsQueryVariables>(FaqsDocument, options);
        }
export type FaqsQueryHookResult = ReturnType<typeof useFaqsQuery>;
export type FaqsLazyQueryHookResult = ReturnType<typeof useFaqsLazyQuery>;
export type FaqsSuspenseQueryHookResult = ReturnType<typeof useFaqsSuspenseQuery>;
export type FaqsQueryResult = Apollo.QueryResult<FaqsQuery, FaqsQueryVariables>;
export const SubmitSubscriberDocument = gql`
    mutation SubmitSubscriber($email: String!) {
  createSubscriber(data: {email: $email}) {
    data {
      id
    }
  }
}
    `;
export type SubmitSubscriberMutationFn = Apollo.MutationFunction<SubmitSubscriberMutation, SubmitSubscriberMutationVariables>;

/**
 * __useSubmitSubscriberMutation__
 *
 * To run a mutation, you first call `useSubmitSubscriberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitSubscriberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitSubscriberMutation, { data, loading, error }] = useSubmitSubscriberMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSubmitSubscriberMutation(baseOptions?: Apollo.MutationHookOptions<SubmitSubscriberMutation, SubmitSubscriberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitSubscriberMutation, SubmitSubscriberMutationVariables>(SubmitSubscriberDocument, options);
      }
export type SubmitSubscriberMutationHookResult = ReturnType<typeof useSubmitSubscriberMutation>;
export type SubmitSubscriberMutationResult = Apollo.MutationResult<SubmitSubscriberMutation>;
export type SubmitSubscriberMutationOptions = Apollo.BaseMutationOptions<SubmitSubscriberMutation, SubmitSubscriberMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: String!) {
  forgotPassword(email: $email) {
    ok
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const FetchOfficialsByAddressDocument = gql`
    query FetchOfficialsByAddress($address: String!) {
  fetchOfficialsByAddress(address: $address) {
    address {
      state
    }
    jurisdictionLabels {
      type
      label
    }
    officials {
      id
      google_id
      name
      photoUrl
      office
      jurisdiction
      party
      website
      active
      picture {
        url
        formats
      }
      contacts {
        type
        value
      }
      social {
        value
        type
      }
    }
  }
}
    `;

/**
 * __useFetchOfficialsByAddressQuery__
 *
 * To run a query within a React component, call `useFetchOfficialsByAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchOfficialsByAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchOfficialsByAddressQuery({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useFetchOfficialsByAddressQuery(baseOptions: Apollo.QueryHookOptions<FetchOfficialsByAddressQuery, FetchOfficialsByAddressQueryVariables> & ({ variables: FetchOfficialsByAddressQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchOfficialsByAddressQuery, FetchOfficialsByAddressQueryVariables>(FetchOfficialsByAddressDocument, options);
      }
export function useFetchOfficialsByAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchOfficialsByAddressQuery, FetchOfficialsByAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchOfficialsByAddressQuery, FetchOfficialsByAddressQueryVariables>(FetchOfficialsByAddressDocument, options);
        }
export function useFetchOfficialsByAddressSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FetchOfficialsByAddressQuery, FetchOfficialsByAddressQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FetchOfficialsByAddressQuery, FetchOfficialsByAddressQueryVariables>(FetchOfficialsByAddressDocument, options);
        }
export type FetchOfficialsByAddressQueryHookResult = ReturnType<typeof useFetchOfficialsByAddressQuery>;
export type FetchOfficialsByAddressLazyQueryHookResult = ReturnType<typeof useFetchOfficialsByAddressLazyQuery>;
export type FetchOfficialsByAddressSuspenseQueryHookResult = ReturnType<typeof useFetchOfficialsByAddressSuspenseQuery>;
export type FetchOfficialsByAddressQueryResult = Apollo.QueryResult<FetchOfficialsByAddressQuery, FetchOfficialsByAddressQueryVariables>;
export const OfficialDocument = gql`
    query Official($id: ID!) {
  official(id: $id) {
    data {
      id
      attributes {
        name
        office
        social {
          value
          type
        }
        party
        picture {
          data {
            attributes {
              url
              formats
            }
          }
        }
        contacts {
          type
          value
        }
      }
    }
  }
}
    `;

/**
 * __useOfficialQuery__
 *
 * To run a query within a React component, call `useOfficialQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfficialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfficialQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOfficialQuery(baseOptions: Apollo.QueryHookOptions<OfficialQuery, OfficialQueryVariables> & ({ variables: OfficialQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OfficialQuery, OfficialQueryVariables>(OfficialDocument, options);
      }
export function useOfficialLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OfficialQuery, OfficialQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OfficialQuery, OfficialQueryVariables>(OfficialDocument, options);
        }
export function useOfficialSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OfficialQuery, OfficialQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OfficialQuery, OfficialQueryVariables>(OfficialDocument, options);
        }
export type OfficialQueryHookResult = ReturnType<typeof useOfficialQuery>;
export type OfficialLazyQueryHookResult = ReturnType<typeof useOfficialLazyQuery>;
export type OfficialSuspenseQueryHookResult = ReturnType<typeof useOfficialSuspenseQuery>;
export type OfficialQueryResult = Apollo.QueryResult<OfficialQuery, OfficialQueryVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($password: String!, $passwordConfirmation: String!, $code: String!) {
  resetPassword(
    password: $password
    passwordConfirmation: $passwordConfirmation
    code: $code
  ) {
    user {
      id
      username
      email
    }
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const loginDocument = gql`
    mutation login($identifier: String!, $password: String!) {
  login(input: {identifier: $identifier, password: $password}) {
    jwt
    user {
      ...authenticatedUserFields
    }
  }
}
    ${authenticatedUserFieldsFragmentDoc}`;
export type loginMutationFn = Apollo.MutationFunction<loginMutation, loginMutationVariables>;

/**
 * __useloginMutation__
 *
 * To run a mutation, you first call `useloginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useloginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useloginMutation({
 *   variables: {
 *      identifier: // value for 'identifier'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useloginMutation(baseOptions?: Apollo.MutationHookOptions<loginMutation, loginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<loginMutation, loginMutationVariables>(loginDocument, options);
      }
export type loginMutationHookResult = ReturnType<typeof useloginMutation>;
export type loginMutationResult = Apollo.MutationResult<loginMutation>;
export type loginMutationOptions = Apollo.BaseMutationOptions<loginMutation, loginMutationVariables>;
export const RegisterDocument = gql`
    mutation Register($username: String!, $first_name: String!, $last_name: String!, $email: String!, $password: String!, $address: String!) {
  register(
    input: {username: $username, email: $email, password: $password, address: $address, first_name: $first_name, last_name: $last_name}
  ) {
    user {
      id
      email
    }
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      username: // value for 'username'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const AutoCompleteDocument = gql`
    query AutoComplete($query: String!) {
  fetchAutoCompleteAddresses(query: $query) {
    matchedAddress
  }
}
    `;

/**
 * __useAutoCompleteQuery__
 *
 * To run a query within a React component, call `useAutoCompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutoCompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutoCompleteQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAutoCompleteQuery(baseOptions: Apollo.QueryHookOptions<AutoCompleteQuery, AutoCompleteQueryVariables> & ({ variables: AutoCompleteQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AutoCompleteQuery, AutoCompleteQueryVariables>(AutoCompleteDocument, options);
      }
export function useAutoCompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AutoCompleteQuery, AutoCompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AutoCompleteQuery, AutoCompleteQueryVariables>(AutoCompleteDocument, options);
        }
export function useAutoCompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AutoCompleteQuery, AutoCompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AutoCompleteQuery, AutoCompleteQueryVariables>(AutoCompleteDocument, options);
        }
export type AutoCompleteQueryHookResult = ReturnType<typeof useAutoCompleteQuery>;
export type AutoCompleteLazyQueryHookResult = ReturnType<typeof useAutoCompleteLazyQuery>;
export type AutoCompleteSuspenseQueryHookResult = ReturnType<typeof useAutoCompleteSuspenseQuery>;
export type AutoCompleteQueryResult = Apollo.QueryResult<AutoCompleteQuery, AutoCompleteQueryVariables>;
export const SubmitImageSuggestionDocument = gql`
    mutation SubmitImageSuggestion($image_source: String!, $image_link: String!, $name: String!, $google_id: Long!, $user: ID!) {
  createSuggestion(
    data: {type: image, source_url: $image_source, link_url: $image_link, official_name: $name, official_google_id: $google_id, user: $user}
  ) {
    data {
      id
    }
  }
}
    `;
export type SubmitImageSuggestionMutationFn = Apollo.MutationFunction<SubmitImageSuggestionMutation, SubmitImageSuggestionMutationVariables>;

/**
 * __useSubmitImageSuggestionMutation__
 *
 * To run a mutation, you first call `useSubmitImageSuggestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitImageSuggestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitImageSuggestionMutation, { data, loading, error }] = useSubmitImageSuggestionMutation({
 *   variables: {
 *      image_source: // value for 'image_source'
 *      image_link: // value for 'image_link'
 *      name: // value for 'name'
 *      google_id: // value for 'google_id'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useSubmitImageSuggestionMutation(baseOptions?: Apollo.MutationHookOptions<SubmitImageSuggestionMutation, SubmitImageSuggestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitImageSuggestionMutation, SubmitImageSuggestionMutationVariables>(SubmitImageSuggestionDocument, options);
      }
export type SubmitImageSuggestionMutationHookResult = ReturnType<typeof useSubmitImageSuggestionMutation>;
export type SubmitImageSuggestionMutationResult = Apollo.MutationResult<SubmitImageSuggestionMutation>;
export type SubmitImageSuggestionMutationOptions = Apollo.BaseMutationOptions<SubmitImageSuggestionMutation, SubmitImageSuggestionMutationVariables>;