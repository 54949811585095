import { getDeviceViewType } from '../../utils';
import DesktopNavigation from './DesktopNavigation';
import MobileNavigation from './MobileNavigation';
import React from 'react';
import { useAuthenticatedUser } from '../AuthenticatedUser/useAuthenticatedUser';

const NavigationComponent: React.FC = () => {
    const [, , signOut] = useAuthenticatedUser();

    return (
        <>
            {getDeviceViewType().includes('mobile') ? (
                <MobileNavigation onSignOut={signOut} />
            ) : (
                <>
                    <DesktopNavigation onSignOut={signOut} />
                    <MobileNavigation onSignOut={signOut} />
                </>
            )}
        </>
    );
};

export default NavigationComponent;