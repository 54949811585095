import { blurOnScroll } from '../../utils';
import { getClass } from '../../utils';
import { IonContent, IonPage } from '@ionic/react';
import cn from 'classnames';
import Footer from '../Footer';
import React from 'react';
import styles from './pagelayout.module.scss';

type Props = {
    children: React.ReactNode;
    withBottomSpacing?: boolean;
    withTopSpacing?: boolean;
    withTopHeaderSpacing?: boolean;
};

const PageLayoutComponent: React.FC<Props> = ({ children, withBottomSpacing, withTopSpacing = true, withTopHeaderSpacing = false }) => {
    const classes = {
        [getClass(styles, 'withBottomSpacing')]: withBottomSpacing,
        [getClass(styles, 'withTopHeaderSpacing')]: withTopHeaderSpacing,
        [getClass(styles, 'withTopSpacing')]: withTopSpacing,
    };

    return (
        <IonPage>
            <IonContent scrollEvents={true} onIonScrollStart={blurOnScroll}>
                <div className={cn(getClass(styles, 'contentWrapper'), classes)}>{children}</div>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default PageLayoutComponent;
