import { add } from 'ionicons/icons';
import { ImageSuggestionModal } from '../shared/ImageSuggestion';
import { IonIcon, IonImg, IonThumbnail } from '@ionic/react';
import { useAuthenticatedUser } from '../AuthenticatedUser';
import DEFAULT_PHOTO_URI from '../../assets/default-user-avatar.png';
import React, { useEffect, useState } from 'react';
import SignInSignUpModal from '../shared/SignInSignUp';
import styles from './styles.module.scss';

type RepThumbnailImageComponentProps = {
    imageSrc: string | null | undefined;
    rep: any | null | undefined;
};

const RepThumbnailImageComponent: React.FC<RepThumbnailImageComponentProps> = ({ imageSrc, rep }) => {
    const [src, setImageSrc] = useState(DEFAULT_PHOTO_URI);
    const [imageSuggestionModalIsOpen, setImageSuggestionModalIsOpen] = useState(false);
    const [signInSignUpModalIsOpen, setSignInSignUpModalIsOpen] = useState(false);
    const [{ isAuthenticated }] = useAuthenticatedUser();

    useEffect(() => {
        if (imageSrc) setImageSrc(imageSrc);
    }, [imageSrc]);

    const handleAddImageIconClicked = () => {
        if (isAuthenticated) {
            setImageSuggestionModalIsOpen(true);
        } else {
            setSignInSignUpModalIsOpen(true);
        }
    };

    return (
        <React.Fragment>
            <IonThumbnail slot='start' className={styles.repImage}>
                <IonImg src={src} onIonError={() => setImageSrc(DEFAULT_PHOTO_URI)} alt='Representative Headshot' />
                {src === DEFAULT_PHOTO_URI && (
                    <IonIcon
                        icon={add}
                        className={styles.ionIcon}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleAddImageIconClicked();
                        }}
                    />
                )}
            </IonThumbnail>
            <ImageSuggestionModal rep={rep} isOpen={imageSuggestionModalIsOpen} setIsOpen={setImageSuggestionModalIsOpen} />
            <SignInSignUpModal
                subTitle='Please sign in or create an account to suggest an image for this representative.'
                isOpen={signInSignUpModalIsOpen}
                setIsOpen={setSignInSignUpModalIsOpen}
            />
        </React.Fragment>
    );
};

export default RepThumbnailImageComponent;
